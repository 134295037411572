import React from 'react'
import {
  List,
  Card,
  CardBody,
  IconButton,
  Button,
  ListItem,
} from '@chakra-ui/react'
import { BsPlayCircle } from 'react-icons/bs'
import { CiBookmarkMinus } from 'react-icons/ci'
import { FaCalendarAlt, FaUsers } from 'react-icons/fa'
import { TbHeartHandshake } from 'react-icons/tb'
import { MdGroup } from 'react-icons/md'
import { NavLink } from 'react-router-dom'

const exploreMore = [
  {
    label: 'Watch now',
    icon: <BsPlayCircle size="24px" />,
    route: '/watch-now',
  },
  {
    label: 'Saved Posts',
    icon: <CiBookmarkMinus size="24px" />,
    route: '/saved-posts',
  },
  {
    label: 'Events',
    icon: <FaCalendarAlt size="20px" />,
    route: '/events',
  },
  {
    label: 'Sponsors',
    icon: <TbHeartHandshake size="26px" />,
    route: '/sponsors',
  },
  {
    label: 'Explore',
    icon: <FaUsers size="20px" />,
    route: '/explore',
  },
  {
    label: 'Groups',
    icon: <MdGroup size="24px" />,
    route: '/groups',
  },
]
export default function ExploreCard() {
  return (
    <Card mb="20px" bg="white" variant="primary">
      <CardBody>
        <List spacing={3}>
          {exploreMore?.map(({ label, icon, route }) => (
            <ListItem key={label}>
              <Button
                as={NavLink}
                to={route}
                variant="link"
                color="text"
                fontWeight="400"
                _hover={{ textDecoration: 'none' }}
                sx={{ '&.active': { fontWeight: '700' } }}
              >
                <IconButton
                  isRound
                  aria-label="watch videos"
                  mr="1.4rem"
                  bg="grayAlpha"
                  color="gray.500"
                  _hover={{ color: 'gray.500' }}
                  icon={icon}
                />
                {label}
              </Button>
            </ListItem>
          ))}
        </List>
      </CardBody>
    </Card>
  )
}
