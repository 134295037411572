import { useQueryClient } from '@tanstack/react-query'
import { UserProfile } from 'utils/types/user.type'

export function useGetCacheData<Type>(arg: string[]): Type | undefined {
  const queryClient = useQueryClient()
  return queryClient.getQueryData(arg)
}

export function useGetUserId() {
  return localStorage.getItem('userId') || ''
}

export function useFilterUsersByName(data: UserProfile[], name: string) {
  return data?.filter(item =>
    item?.fullName?.toLocaleLowerCase().includes(name?.toLocaleLowerCase()),
  )
}
