import React, { useState } from 'react'
import Layout from 'layout'
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaGlobeAfrica, FaUserShield } from 'react-icons/fa'
import { CiSquarePlus } from 'react-icons/ci'
import { HiUsers } from 'react-icons/hi'
import PostCard from 'components/postCard'
import { useLocation } from 'react-router-dom'
import GroupManageUsers from './groupManageUsers'
import { useGetGroup } from 'utils/apis/groups.api'
import coverImg from 'assets/images/group/groupCoverImg.png'
import ConfirmModal from 'components/confirmModal'
import { useDeleteGroup, useLeaveGroup } from 'utils/apis/groups.api'
import CreateGroup from 'views/groups/createGroupModal'

const style = { color: '#6374A0', fontSize: '24px' }

export default function GroupDetails() {
  const [selectedType, setSelectedType] = useState('post')

  const { state } = useLocation()
  const { mutate: deleteGroup, isLoading: isDeleting } = useDeleteGroup()
  const { mutate: leaveGroup, isLoading: isLeaving } = useLeaveGroup()
  const { data: groupDetails } = useGetGroup(state?.groupId)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isEdit,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure()

  const handleGroupActions = (type: string) => {
    setSelectedType(type)
    if (type === 'deleteGroup' || type === 'removeGroup') onOpen()
    if (type === 'editGroup') onOpenEdit()
  }

  const handleModalActions = () => {
    if (selectedType === 'deleteGroup' && state?.groupId)
      deleteGroup(state?.groupId)
    else if (state?.groupId) leaveGroup(state?.groupId)
  }

  return (
    <Layout handleActions={handleGroupActions}>
      <>
        <Box boxShadow="base" bg="white" rounded="10px">
          <Image
            src={groupDetails?.captureImageURL || coverImg}
            w="100%"
            maxH="200px"
            minH="180px"
            objectFit="cover"
          />
          <Box p="15px 57px">
            <Flex justify="space-between" alignItems="center">
              <Text className="fs--16 fw--600">{groupDetails?.groupName}</Text>
              <Button
                className="fs--12 fw--400"
                bg="transparent"
                px="0"
                color="text"
                _active={{}}
                _hover={{}}
                leftIcon={<FaGlobeAfrica fontSize="13px" color="#B8B7B7" />}
              >
                not define
              </Button>
            </Flex>
            <Text className="fs--12 fw--400" mt="10px">
              {groupDetails?.description}
            </Text>
            <Flex justify="space-between" alignItems="center" my="10px">
              <Flex gap="15px" alignItems="center">
                <IconButton
                  bg="grayAlpha"
                  color="gray.500"
                  _active={{}}
                  _hover={{ color: 'gray.500' }}
                  aria-label="plus"
                  isRound
                  icon={<CiSquarePlus style={style} />}
                />
                <Text className="fs--12">
                  {groupDetails?.postsCount} Total Posts
                </Text>
              </Flex>
            </Flex>
            <Divider />
            <Flex justify="space-between" alignItems="center" my="10px">
              <Flex gap="15px" alignItems="center">
                <IconButton
                  bg="grayAlpha"
                  color="gray.500"
                  _active={{}}
                  _hover={{ color: 'gray.500' }}
                  aria-label="plus"
                  isRound
                  icon={<HiUsers style={style} />}
                />
                <Text className="fs--12">
                  {groupDetails?.membersCount} Users
                </Text>
              </Flex>
              <AvatarGroup size="xs" max={4} mt="5px">
                {groupDetails?.topMembers?.map(({ profilePicURL, id }) => (
                  <Avatar key={id} name={id} src={profilePicURL} />
                ))}
              </AvatarGroup>
            </Flex>
            <Divider />
            <Flex justify="space-between" alignItems="center" my="10px">
              <Flex gap="15px" alignItems="center">
                <IconButton
                  bg="grayAlpha"
                  color="gray.500"
                  _active={{}}
                  _hover={{ color: 'gray.500' }}
                  aria-label="plus"
                  isRound
                  icon={<FaUserShield style={style} />}
                />
                <Text className="fs--12">
                  {groupDetails?.admins?.length} Moderators
                </Text>
              </Flex>
              <AvatarGroup size="xs" max={4} mt="5px">
                {groupDetails?.topModerators?.map(({ profilePicURL, id }) => (
                  <Avatar key={id} name={id} src={profilePicURL} />
                ))}
              </AvatarGroup>
            </Flex>
          </Box>
        </Box>
        {selectedType === 'post' ||
        selectedType === 'editGroup' ||
        selectedType === 'deleteGroup' ? (
          <PostCard />
        ) : (
          <GroupManageUsers groupId={state?.groupId} type={selectedType} />
        )}

        <ConfirmModal
          type={selectedType === 'deleteGroup' ? 'delete' : 'remove'}
          title={
            selectedType === 'deleteGroup' ? 'Delete Group' : 'Leave Group'
          }
          message="Are you sure you want to leave this group? This action cannot be
                undone."
          isOpen={isOpen}
          onClose={onClose}
          onOk={handleModalActions}
          isLoading={selectedType === 'deleteGroup' ? isDeleting : isLeaving}
        />
        {groupDetails && (
          <CreateGroup
            isOpen={isEdit}
            onClose={onCloseEdit}
            groupDetails={groupDetails}
          />
        )}
      </>
    </Layout>
  )
}
