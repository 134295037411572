import React from 'react'
import Layout from 'layout'
import PostCard from 'components/postCard'
import UploadContentCard from 'components/postContent'
import { useGetAllPost } from 'utils/apis/post.api'

export default function Landing() {
  const { data: allPosts } = useGetAllPost()
  return (
    <Layout handleActions={() => console.log('e')}>
      <>
        <UploadContentCard />
        {allPosts?.map(post => (
          <PostCard key={post?.post?.id} postDetails={post} />
        ))}
      </>
    </Layout>
  )
}
