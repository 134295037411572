import React, { useState } from 'react'
import {
  Stack,
  Flex,
  Image,
  Text,
  Box,
  Button,
  AvatarGroup,
  Avatar,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Container,
  TagLabel,
  Tag,
  Card,
  Heading,
  CardBody,
  CardHeader,
} from '@chakra-ui/react'
import IconButton from 'components/iconButton'
import EventImg from 'assets/images/EventImg.png'
import { FaShare } from 'react-icons/fa'
import { Event } from 'utils/types/events.type'
import {
  EventAttendings,
  EventDuration,
  EventLocation,
  EventOccurTime,
  EventOnline,
} from 'assets/icons'
import { Link } from 'react-router-dom'
import {
  useCreateEventStatus,
  useUpdateEventStatus,
} from 'utils/apis/events.api'
import { UserProfile } from 'utils/types/user.type'

interface Props {
  specific?: boolean
  event: Event
  status?: {
    id: string
    statusType: 'going' | 'ignore' | 'interested'
  }
}
export default function EventCard({ event, specific, status }: Props) {
  const [modalType, setModalType] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { mutate: updateStatus, isLoading } = useUpdateEventStatus()
  const { mutate: createStatus, isLoading: createLoading } =
    useCreateEventStatus()

  const handleEventStatus = (type: string) => {
    if (event?.id && status?.statusType != 'going') {
      if (status?.id) {
        updateStatus({
          id: status?.id,
          eventID: event?.id,
          statusType: type,
        })
      } else {
        createStatus({
          eventID: event?.id,
          statusType: type,
        })
      }
    }
  }

  const handleModal = (type: string) => {
    setModalType(type)
    onOpen()
  }

  const dateDiff =
    new Date().setHours(
      parseInt(event?.startTime?.split(':')[0]),
      parseInt(event?.startTime?.split(':')[1]),
    ) -
    new Date().setHours(
      parseInt(event?.endTime?.split(':')[0]),
      parseInt(event?.endTime?.split(':')[1]),
    )
  // console.log('dateDiff', )
  return (
    <Card
      bg="white"
      w="100%"
      mb={8}
      overflow="hidden"
      variant="primary"
      p={specific ? '0' : '30px 35px'}
    >
      {!specific && (
        <CardHeader p="0" pb="25px">
          <Flex gap="10px">
            <Avatar src={event?.user?.profilePicURL} />
            <Box>
              <Text fontWeight="600">
                {event?.user?.fullName}{' '}
                <Text as="span" fontWeight="300">
                  shared
                </Text>{' '}
                event
              </Text>
              <Text fontSize="14px">
                {new Date(event?.postedDate || '')?.toDateString()} at{' '}
                {new Date(event?.postedDate || '')?.toLocaleTimeString()}
              </Text>
            </Box>
          </Flex>
        </CardHeader>
      )}
      <Box bg="blackAlpha.400" position="relative">
        <Link to={`/event-details/${event?.id}`}>
          <Image
            minH="200px"
            w="100%"
            maxH="200px"
            objectFit="cover"
            src={event?.captureImageURL || EventImg}
          />
        </Link>
      </Box>
      <CardBody position="relative">
        <Box
          as={Link}
          to={`/event-details/${event?.id}`}
          boxShadow="md"
          bg="white"
          rounded="6px"
          padding="5px 12px"
          textAlign="center"
          mt="-52px"
          width="max-content"
          display="block"
        >
          <Text as="span" display="block" className="fs--20 fw--700">
            {`${new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(
              Number(event?.startDate),
            )}`}
          </Text>
          <Text as="span" color="error" className="fs--16 fw--700">
            {`${new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
              Number(event?.startDate),
            )}`}
          </Text>
        </Box>
        <Heading as="h2" my="15px" fontSize="18px">
          <Link to={`/event-details/${event?.id}`}>{event?.eventName}</Link>
        </Heading>

        {specific ? (
          <Flex mt="10px" alignItems="center" justifyContent="space-between">
            <EventUtils
              icon={<EventAttendings />}
              label={`${event?.goingCount} Attending`}
            />
            <AvatarGroup
              onClick={() => {
                handleModal('Attendees')
              }}
              size="sm"
              max={2}
              cursor="pointer"
            >
              {event?.topAttendees?.map(attendee => {
                return (
                  <Avatar
                    key={attendee?.id}
                    name="Ryan Florence"
                    src={attendee?.profilePicURL}
                  />
                )
              })}
            </AvatarGroup>
          </Flex>
        ) : (
          <>
            <EventUtils
              icon={<EventAttendings />}
              label={`${event?.interestedCount} interested - ${event?.goingCount} Attending`}
            />
          </>
        )}
        <EventUtils
          icon={<EventOccurTime />}
          label={`${new Date(event?.startDate)?.toDateString()} at ${
            event?.startTime
          }`}
        />
        {specific && (
          <>
            <EventUtils
              icon={<EventDuration />}
              label={`Event Duration ${(Math.abs(dateDiff) / 36e5).toFixed(
                1,
              )} hr`}
            />
            <EventUtils icon={<EventOnline />} label={`not define`} />
            <EventUtils icon={<EventLocation />} label={event?.location} />
            <Text mt="20px" as="p">
              Hosts
            </Text>
            <Flex mt="15px" display="flex" justifyContent="space-between">
              <AvatarGroup size="sm" max={2}>
                {event?.coHosts?.map(host => (
                  <Avatar
                    key={host?.id}
                    name="Ryan Florence"
                    src={host?.profilePicURL}
                  />
                ))}
              </AvatarGroup>
              <IconButton
                onClick={() => handleModal('Host')}
                label="View All"
              />
            </Flex>
            <Text as="h5" mt={2} mb={2} fontSize={16} fontWeight={500}>
              Description
            </Text>
            <Text as="p" color="muted" fontSize={14}>
              {event?.detail}
            </Text>
          </>
        )}
        <Flex alignItems="center" mt="15px">
          <Button
            variant={status?.statusType === 'interested' ? 'solid' : 'outline'}
            onClick={() =>
              handleEventStatus(
                status?.statusType === 'interested' ? 'ignore' : 'interested',
              )
            }
            isLoading={isLoading || createLoading}
            className="fs--14 fw--500"
          >
            Interested
          </Button>
          <Button
            onClick={() => handleEventStatus('going')}
            isLoading={isLoading || createLoading}
            className="fs--14 fw--400"
            ml="8px"
            mr="auto"
            variant={status?.statusType === 'going' ? 'solid' : 'outline'}
          >
            {status?.statusType === 'going' ? 'Attending' : 'Attend'}
          </Button>
          {!specific && <EventUtils icon={<FaShare />} label={`Share`} />}
        </Flex>
      </CardBody>
      <UsersModal
        isOpen={isOpen}
        onClose={onClose}
        users={
          (modalType === 'Attendees' ? event?.topAttendees : event?.coHosts) ||
          []
        }
        title={modalType}
      />
    </Card>
  )
}

function EventUtils({ icon, label }: { icon: JSX.Element; label?: string }) {
  return (
    <Text color="muted" as={Flex} alignItems="center" mb="10px">
      {icon}
      <Text fontSize={14} as="span" mx="2">
        {label}
      </Text>
    </Text>
  )
}

interface ModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  users: Partial<UserProfile>[]
}

function UsersModal({ title, users, isOpen, onClose }: ModalProps) {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent padding="5px" rounded="18px">
        <ModalBody>
          <Stack>
            <Text className="fw--600" mt="10px" mb="15px">
              {title}
            </Text>
            <Container px="0px">
              {users?.map(item => {
                return (
                  <Box key={item?.id} display="flex" mb={4}>
                    <Image
                      boxSize="60px"
                      borderRadius="full"
                      src={item?.profilePicURL}
                    />
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      ml="10px"
                      borderBottom="2px"
                      borderColor="gray.200"
                    >
                      <Flex direction="column">
                        <Text as="p">
                          {item?.fullName}{' '}
                          <Tag
                            mx="15px"
                            size="sm"
                            borderRadius="6px"
                            variant="solid"
                            bg="gray.100"
                          >
                            <TagLabel className="fw--600 fs--11" color="muted">
                              Creator
                            </TagLabel>
                          </Tag>
                        </Text>
                        <Text color="muted" fontSize={14} mt={1} as="p">
                          {item?.username}
                        </Text>
                      </Flex>
                      <Button
                        mt={2}
                        fontWeight="400"
                        fontSize={16}
                        variant="outline"
                        size="sm"
                      >
                        Follow
                      </Button>
                    </Box>
                  </Box>
                )
              })}
            </Container>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
