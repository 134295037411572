import React, { useState, useEffect } from 'react'
import {
  Menu,
  MenuList,
  MenuButton,
  Image,
  Stack,
  useDisclosure,
  Box,
  Text,
  Button,
  SimpleGrid,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Input,
  FormLabel,
} from '@chakra-ui/react'
import SponsorBg from 'assets/images/SponsorBg.png'
import FileUploader from 'components/fileUploadCard'
import { MdDelete, MdModeEditOutline } from 'react-icons/md'
import IconButton from 'components/iconButton'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import ConfirmModal from 'components/confirmModal'
import { useForm } from 'react-hook-form'
import { BsCameraFill, BsPlus } from 'react-icons/bs'
import noSponsor from 'assets/images/NoSponsors.png'
import { Link } from '@chakra-ui/react'
import Header from 'components/header'
import { useGetAllSponsors } from 'utils/apis/sponsors.api'

export default function Sponsors() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [modalType, setmodalType] = useState('')

  const { data: sponsorList } = useGetAllSponsors()

  const editAndcreate = (type: string) => {
    setmodalType(type)
    onOpen()
  }
  const deleteSponsor = () => {
    setmodalType('delete')
    onOpen()
  }
  const viewSponsor = () => {
    setmodalType('view')
    onOpen()
  }
  return (
    <Stack mx={0}>
      <Header />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="320px"
        backgroundSize="cover"
        bgImage={`url(${SponsorBg})`}
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <Text className="fw--800 fs--40" fontStyle="italic" color="white">
          Want to join club?
        </Text>
        <Text className="fw--400 fs--20" fontStyle="italic" color="white">
          Interested in learning more about how your brand can take new values?
        </Text>
      </Box>
      {sponsorList?.length ? (
        <>
          <Stack display="flex" alignItems="center" pt="47px" pb="130px">
            <Box width="1024px">
              <Flex
                mb="15px"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text className="fs--18 fw--700">Top Sponsors</Text>
                <IconButton
                  color="primary.400"
                  onClick={() => {
                    editAndcreate('Create')
                  }}
                  fontWeight="700"
                  label="Add"
                  Icon={<BsPlus fontSize="20px" />}
                />

                <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  size="xl"
                  isCentered={true}
                >
                  <ModalOverlay />
                  {modalType === 'delete' ? (
                    <ConfirmModal
                      isLoading={false}
                      type={modalType}
                      title="Delete Sponsor !"
                      message="Are you sure you want to delete this sponsor? This action cannot be undone."
                      isOpen={isOpen}
                      onClose={onClose}
                      onOk={onClose}
                    />
                  ) : modalType === 'Update' || modalType === 'Create' ? (
                    <EditandUpdateSponsor
                      openCheck={isOpen}
                      close={onClose}
                      open={onOpen}
                      type={modalType}
                    />
                  ) : null}
                </Modal>
              </Flex>
              <SimpleGrid columns={3} spacing={5}>
                {sponsorList?.map(sponsor => {
                  return (
                    <Box key={sponsor?.id}>
                      <Image
                        cursor="pointer"
                        mb="5px"
                        width="100%"
                        height="192px"
                        src={sponsor?.captureImageURL}
                        onClick={viewSponsor}
                      />
                      <Flex
                        mb="5px"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text
                          cursor="pointer"
                          onClick={viewSponsor}
                          className="fw--600"
                        >
                          {sponsor?.title}
                        </Text>
                        <Menu>
                          <MenuButton>
                            <BiDotsHorizontalRounded
                              color="#7C167D"
                              fontSize="30px"
                            />
                          </MenuButton>
                          <MenuList
                            shadow="none"
                            _hover={{}}
                            _active={{}}
                            border={0}
                            bg="transparent"
                            width="130px"
                            maxW="130px"
                            padding="5px 10px"
                          >
                            <Flex
                              justifyContent="center"
                              alignItems="start"
                              direction="column"
                              width="180px"
                              bg="white"
                              rounded={10}
                            >
                              <Flex mt="10px" ml="10px" mb="5px">
                                <IconButton
                                  onClick={() => {
                                    editAndcreate('Update')
                                  }}
                                  fontSize="16px"
                                  label="Edit Sponsor"
                                  fontWeight="500"
                                  Icon={
                                    <MdModeEditOutline
                                      color="#7C167D"
                                      fontSize="21px"
                                    />
                                  }
                                />
                              </Flex>
                              <Flex ml="10px" mb="10px">
                                <IconButton
                                  onClick={deleteSponsor}
                                  fontSize="16px"
                                  label="Delete sponsor "
                                  Icon={
                                    <MdDelete color="#7C167D" fontSize="21px" />
                                  }
                                />
                              </Flex>
                            </Flex>
                          </MenuList>
                        </Menu>
                      </Flex>
                      <Link href={sponsor?.hyperlink} isExternal>
                        Click here to visit Sponsor
                      </Link>
                    </Box>
                  )
                })}
              </SimpleGrid>
            </Box>
            {modalType === 'view' ? (
              <ViewSponsor
                openCheck={isOpen}
                close={onClose}
                open={onOpen}
                type={modalType}
              />
            ) : null}
          </Stack>
        </>
      ) : (
        <Stack display="flex" alignItems="center" justifyContent="center">
          <Image src={noSponsor} width="646px" height="600px" />
        </Stack>
      )}
    </Stack>
  )
}
type props = {
  close: () => void
  type: string
  open: () => void
  openCheck: boolean
}
interface IFormInput {
  title: string
  discription: string
  webLink: string
}
function EditandUpdateSponsor({ type, close }: props) {
  const { register, setValue, getValues } = useForm<IFormInput>()
  const UpdateSponsors = () => {
    const data = getValues(['title', 'discription', 'webLink'])
    console.log(data)
  }
  useEffect(() => {
    if (type === 'Update') {
      setValue('title', 'Danish')
      setValue('discription', 'This is the test discription.')
      setValue('webLink', 'www.google.com')
    }
  }, [])
  return (
    <ModalContent m={0} p={0} rounded="18px">
      <ModalBody m={0} p={0}>
        <Flex
          justifyContent="center"
          height="160px"
          borderTopLeftRadius="18px"
          borderTopRightRadius="18px"
          backgroundSize="cover"
          bgImage={`url(${SponsorBg})`}
          bgPosition="center"
          bgRepeat="no-repeat"
        >
          <Flex alignItems="center">
            <FileUploader
              handleFile={event => console.log('event', event)}
              icon={
                <BsCameraFill
                  fill={type === 'Create' ? 'black' : 'white'}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '50px',
                    padding: '10px',
                    width: '50px',
                    height: '50px',
                  }}
                />
              }
              text="Add cover Image"
              direction="column"
            />
          </Flex>
        </Flex>
        <Box padding="25px 50px">
          <Flex direction="column">
            <form>
              <FormLabel className="fs--18 fw--700" htmlFor="name">
                Sponsor
              </FormLabel>
              <FormLabel mt="15px" className="fs--16 fw--500" htmlFor="name">
                Title
              </FormLabel>
              <Input
                {...register('title', { required: true, maxLength: 20 })}
              />
              <FormLabel mt="15px" className="fs--16 fw--500" htmlFor="name">
                Description
              </FormLabel>
              <Input
                {...register('discription', { pattern: /^[A-Za-z]+$/i })}
              />
              <FormLabel mt="15px" className="fs--16 fw--500" htmlFor="name">
                Web Link
              </FormLabel>
              <Input
                {...register('webLink', { required: true, maxLength: 20 })}
              />
            </form>
          </Flex>
        </Box>
      </ModalBody>

      <ModalFooter display="flex" alignItems="center" justifyContent="center">
        <Button
          className="fs--18 fw--500"
          width="210px"
          variant="outline"
          mr={3}
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          className="fs--18 fw--500"
          width="210px"
          onClick={UpdateSponsors}
        >
          {type}
        </Button>
      </ModalFooter>
    </ModalContent>
  )
}
//modal for viewing the sponsor
function ViewSponsor({ close, openCheck }: props) {
  return (
    <Modal isOpen={openCheck} onClose={close} isCentered={true} size="xl">
      <ModalOverlay />
      <ModalContent m={0} p={0} rounded="18px">
        <ModalBody m={0} p={0}>
          <Flex
            justifyContent="center"
            height="160px"
            borderTopLeftRadius="18px"
            borderTopRightRadius="18px"
            backgroundSize="cover"
            bgImage={`url(${Sponsors})`}
            bgPosition="center"
            bgRepeat="no-repeat"
          ></Flex>
          <Box padding="25px 50px">
            <Flex
              border="2px"
              borderTopColor="transparent"
              borderLeftColor="transparent"
              borderRightColor="transparent"
              borderBottomColor="gray.300"
              mb="15px"
              pb="15px"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text className="fs--18 fw--600" as="p">
                Sponsor Title
              </Text>
              <Text className="fs--16 fw--600">
                Visit Now{' '}
                <Text className="fw--300" ml="10px" as="span">
                  www.google.com
                </Text>
              </Text>
            </Flex>
            <Text as="p" textAlign="justify">
              Lorem ipsum dolor sit amet consectetur. Convallis phasellus fames
              vel diam et sit. A suspendisse fermentum nunc senectus nunc cum
              varius. Id dictumst nam et lectus vitae ac ultrices duis senectus.
              Placerat morbi augue sed consectetur eu a nunc lorem. Laoreet
              vestibulum donec sit lectus feugiat vel. Congue fringilla nec diam
              nunc. Vel.
            </Text>
          </Box>
        </ModalBody>

        <ModalFooter
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ borderTop: '2px solid #F6F6F6' }}
        >
          <Button
            className="fs--18 fw--500"
            width="210px"
            variant="outline"
            mr={3}
            onClick={close}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
