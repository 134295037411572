import React from 'react'
import { Box, Flex, FormControl, FormLabel } from '@chakra-ui/react'
import { VisuallyHiddenInput } from '@chakra-ui/react'
type UploadProperty = {
  icon: JSX.Element
  text: string
  direction?: string
  handleFile: React.ChangeEventHandler<HTMLInputElement> | undefined
}
export default function FileUploader({
  direction,
  icon,
  text,
  handleFile,
}: UploadProperty) {
  return (
    <FormControl
      as={Flex}
      direction={direction || 'row'}
      alignItems="center"
      m="0"
    >
      <FormLabel
        display="flex"
        color="white"
        className="fs--14"
        htmlFor="inputTag"
        p="7px"
        cursor="pointer"
        m="0"
      >
        <Box bg="whiteAlpha.400" rounded="full" mr={direction || 3} p="4px">
          {icon}
        </Box>{' '}
        {text}
      </FormLabel>
      <VisuallyHiddenInput id="inputTag" type="file" onChange={handleFile} />
    </FormControl>
  )
}
