import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  AddUserToGroup,
  CreateGroupType,
  Group,
  GroupModerator,
} from 'utils/types/groups.type'
import { client } from './api-client'
import { useGetUserId } from 'utils/hooks'
import { UserProfile } from 'utils/types/user.type'
import { useNavigate } from 'react-router-dom'

export const useCreateGroup = () => {
  const queryClient = useQueryClient()
  return useMutation<Group, Error, CreateGroupType>(
    (data: CreateGroupType) => client(`groups/addGroup`, { data }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getUserGroups'])
      },
    },
  )
}
export const useUpdateGroup = () => {
  const queryClient = useQueryClient()
  return useMutation<Group, Error, Group>(
    (data: CreateGroupType) => {
      return client(`groups/updateGroup`, { data })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroupById'])
      },
    },
  )
}

export const useGetUserGroups = (offset: number, limit: number) =>
  useQuery<{ groups: Group[] }, Error>(
    ['getUserGroups', { offset: offset, limit: limit }],
    () => {
      return client(
        `groups/getUserGroupsWithAllData?offset=${offset}&limit=${limit}`,
      )
    },
  )

export const useGetPopularGroups = (offset: number, limit: number) =>
  useQuery<Group[], Error>(
    ['getPopularGroups', { offset: offset, limit: limit }],
    () => client(`groups/getDiscoverGroups?offset=${offset}&limit=${limit}`),
  )

export const useGetGroup = (id: string) =>
  useQuery<Group, Error>(['getGroupById', id], () =>
    client(`groups/getGroupById/${id}`),
  )

export const useJoinGroup = () => {
  const userId = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, string>(
    groupId => client(`groups/requestToJoinGroup/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getPopularGroups'])
        queryClient.invalidateQueries(['getGroupByName'])
      },
    },
  )
}

export const useCancelJoinGroup = () => {
  const userId = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, string>(
    groupId => client(`groups/leaveGroup/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getPopularGroups'])
        queryClient.invalidateQueries(['getGroupByName'])
      },
    },
  )
}

export const useGetModerators = (groupId?: string) =>
  useQuery<GroupModerator[], Error>(
    ['getModerators', groupId],
    () => client(`groups/getGroupAdmins/${groupId}`),
    {
      enabled: !!groupId,
    },
  )

export const useGetGroupUsers = (groupId?: string) =>
  useQuery<GroupModerator[], Error>(
    ['getGroupUsers', groupId],
    () => client(`groups/getGroupMembers/${groupId}`),
    {
      enabled: !!groupId,
    },
  )

export const useGetGroupSuggestedUsers = (groupId?: string) =>
  useQuery<UserProfile[], Error>(
    ['getGroupSuggestUser', groupId],
    () => client(`groups/suggestUser/${groupId}`),
    {
      enabled: !!groupId,
    },
  )

export const useGetRequestedUsers = (groupId?: string) =>
  useQuery<GroupModerator[], Error>(
    ['getRequestedUsers', groupId],
    () => client(`groups/getRequestedUsers/${groupId}`),
    {
      enabled: !!groupId,
    },
  )

export const useApproveUserRequest = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, { userId: string; groupId: string }>(
    ({ userId, groupId }) =>
      client(`groups/approveGroupUser/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getRequestedUsers'])
        queryClient.invalidateQueries(['getGroupById'])
      },
    },
  )
}

export const useAddUserToGroup = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, AddUserToGroup>(
    data => client(`groups/createGroupStatus`, { data }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getGroupUsers'])
        queryClient.invalidateQueries(['getModerators'])
        queryClient.invalidateQueries(['getGroupSuggestUser'])
      },
    },
  )
}

export const useRemoveModerator = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, { userId: string; groupId: string }>(
    ({ userId, groupId }) =>
      client(`groups/removeGroupAdmin/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getModerators'])
      },
    },
  )
}

export const useRemoveUser = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, { userId: string; groupId: string }>(
    ({ userId, groupId }) =>
      client(`groups/removeGroupUser/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getGroupUsers'])
        queryClient.invalidateQueries(['getRequestedUsers'])
      },
    },
  )
}
export const useLeaveGroup = () => {
  const navigate = useNavigate()
  const userId = useGetUserId()
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, string>(
    groupId => client(`groups/leaveGroup/${userId}/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getUserGroups'])
        navigate('/groups')
      },
    },
  )
}

export const useDeleteGroup = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, string>(
    groupId => client(`groups/deleteGroup/${groupId}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getUserGroups'])
        navigate('/groups')
      },
    },
  )
}

export const useGetGroupsByName = (name: string, limit = 10) =>
  useQuery<Group[], Error>(['getGroupByName', name, limit], ({ signal }) =>
    client(`groups/searchGroupsByName/${name}?offset=0&&limit=${limit}`, {
      signal,
    }),
  )
