import React from 'react'
import {
  Text,
  Button,
  Flex,
  ModalContent,
  ModalHeader,
  Modal,
  ModalOverlay,
} from '@chakra-ui/react'
import { DeleteEvent } from 'assets/icons'

type Props = {
  isOpen: boolean
  onOk: () => void
  onClose: () => void
  title: string
  message: string
  type: 'delete' | 'remove'
  isLoading: boolean
}

export default function ConfirmModal({
  isOpen,
  onClose,
  onOk,
  title,
  message,
  type,
  isLoading,
}: Props) {
  return (
    <Modal
      isCentered
      onClose={() => (isLoading ? null : onClose)}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <DeleteEvent />
            <Text px="2" as="p">
              <Text fontSize="18px" as="p" fontWeight="500">
                {title}
              </Text>
              <Text fontSize="14px" color="muted" fontWeight="400" as="p">
                {message}
              </Text>
            </Text>
          </Flex>
          <Flex alignItems="center" justifyContent="end">
            <Button
              disabled={isLoading}
              onClick={onClose}
              _hover={{}}
              _focus={{}}
              _active={{}}
              bg="grayAlpha"
              color="gray.500"
              fontSize="16px"
              fontWeight="500"
              width="138px"
              mx="12px"
            >
              Cancel
            </Button>
            <Button
              onClick={onOk}
              _hover={{}}
              _focus={{}}
              _active={{}}
              bg="error"
              fontSize="16px"
              fontWeight="500"
              width="138px"
              isLoading={isLoading}
            >
              {type === 'delete' ? 'Delete' : 'Remove'}
            </Button>
          </Flex>
        </ModalHeader>
      </ModalContent>
    </Modal>
  )
}
