import React from 'react'
import Layout from 'layout'
import {
  Stack,
  Text,
  Flex,
  Image,
  Box,
  Menu,
  MenuList,
  MenuButton,
  Heading,
  Card,
  CardBody,
} from '@chakra-ui/react'
import NoPost from 'assets/images/noPost.png'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import IconButton from 'components/iconButton'
import { FaTelegramPlane } from 'react-icons/fa'
import { BsBookmarkDashFill } from 'react-icons/bs'
import { useGetSavedPosts, useUnSavePost } from 'utils/apis/post.api'
import { useGetUserId } from 'utils/hooks'
import { SavedPost } from 'utils/types/post.type'
import { diff } from 'utils/helper'

export default function SavePost() {
  const userId = useGetUserId()
  // const { data: profileData } = useGetProfile(userId)
  const { data: myPosts } = useGetSavedPosts(1, 20, userId)
  return (
    <Layout handleActions={() => console.log('e')}>
      {myPosts?.length ? (
        <Stack>
          <Flex direction="column" alignItems="start">
            {myPosts?.map(post => (
              <SavePostCard key={post?.id} post={post} />
            ))}
          </Flex>
        </Stack>
      ) : (
        <Card bg="white" padding="60px 40px" textAlign="center">
          <Image
            marginBottom="40px"
            display="inline"
            mx="auto"
            width="70%"
            src={NoPost}
          />
          <Heading as="h2" mb="15px" className="fs--26 fw--700">
            Add Bookmarks
          </Heading>
          <Text as="p" textAlign="center" className="fs--18" color="muted">
            Don’t forget to bookmark the posts you like the most so that you can
            find those easily over here
          </Text>
        </Card>
      )}
    </Layout>
  )
}
function SavePostCard({ post }: { post: SavedPost }) {
  const { mutate: unSavePost } = useUnSavePost()
  return (
    <Card
      mb="15px"
      w="100%"
      bg="white"
      direction={{ base: 'column', sm: 'row' }}
      overflow="hidden"
      p="20px"
    >
      {post?.type === 'image' && (
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '200px' }}
          minW="200px"
          border="1px solid"
          borderColor="muted"
          rounded="7px"
          src={post?.captureFileURL}
          fallbackSrc="https://placehold.co/600x400?text=Image+Not+Available"
        />
      )}
      {post?.type === 'poll' && (
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '200px' }}
          minW="200px"
          border="1px solid"
          borderColor="muted"
          rounded="7px"
          fallbackSrc="https://placehold.co/600x400?text=Poll"
        />
      )}
      <CardBody p="0 0 0 10px">
        <Box w="100%" ml="12px">
          <Flex alignItems="center" justifyContent="space-between">
            <Text className="fs--18 fw--700" noOfLines={[3, 2, 1]}>
              {post?.text}
            </Text>
            <Menu>
              <MenuButton>
                <BiDotsHorizontalRounded color="#7C167D" fontSize="30px" />
              </MenuButton>
              <MenuList width="130px" maxW="130px" padding="5px 10px">
                <Flex
                  justifyContent="center"
                  alignItems="start"
                  direction="column"
                >
                  <IconButton
                    fontSize="16px"
                    label="Share"
                    fontWeight="500"
                    Icon={<FaTelegramPlane color="#7C167D" fontSize="21px" />}
                  />
                  <IconButton
                    fontSize="16px"
                    label="Un Save"
                    onClick={() => unSavePost(post?.id)}
                    Icon={
                      <BsBookmarkDashFill color="#7C167D" fontSize="21px" />
                    }
                  />
                </Flex>
              </MenuList>
            </Menu>
          </Flex>
          {post?.type === 'poll' && (
            <Text mt="5px" className="fs--14 fw--400" noOfLines={[2, 2, 3]}>
              Total votes {post?.poll?.totalVotesCount}
            </Text>
          )}
          <Text mt="5px" className="fs--14">
            Post -{' '}
            {`${diff(
              new Date(post?.postedDate).getTime(),
              new Date().getTime(),
            )}`}
          </Text>
          <Text as={Flex} mt="5px" className="fs--14">
            <Image
              mr="8px"
              src={post?.user?.profilePicURL}
              boxSize="25px"
              rounded="full"
              fallbackSrc="fallbackSrc"
            />
            Saved from {post?.user?.fullName} post
          </Text>
        </Box>
      </CardBody>
    </Card>
  )
}
