import React, { useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Image,
  Card,
  CardHeader,
  Avatar,
  CardBody,
  CardFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  HStack,
  VStack,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { BsThreeDots, BsBookmarkDashFill } from 'react-icons/bs'
import { AiFillLike } from 'react-icons/ai'
import { FaRegComment, FaTelegramPlane } from 'react-icons/fa'
import { FiEdit2 } from 'react-icons/fi'
import { MdDelete, MdReport } from 'react-icons/md'
import { PostResponse } from 'utils/types/post.type'
import IconButton from 'components/iconButton'
import {
  useDeletePost,
  useGetComments,
  usePostLike,
  usePostUnLike,
  useSavePost,
  useUnSavePost,
} from 'utils/apis/post.api'
import { useGetUserId } from 'utils/hooks'
import { diff } from 'utils/helper'
import Plyr from 'plyr-react'
import { UserPostComment, PostComment } from './comment'
import { PollContent } from './pollContent'

export default function PostCard({
  postDetails,
}: {
  postDetails?: PostResponse
}) {
  const post = postDetails?.post
  const [isReply, setReply] = useState(false)
  const [commentId, setCommentId] = useState('')
  const { mutate: savePost } = useSavePost()
  const { mutate: unSavePost } = useUnSavePost()
  const { isOpen, onOpen } = useDisclosure()
  const { mutate: likePost } = usePostLike()
  const { mutate: unLikePost } = usePostUnLike()
  const { mutate: deletePost } = useDeletePost()
  const { data: allComments } = useGetComments(post?.id)
  const isAdmin = post?.userID === useGetUserId()
  const { isOpen: isComments, onOpen: openComment } = useDisclosure()

  return (
    <Card bg="white" my="0.5rem">
      <CardHeader pb="0">
        <Flex alignItems="center">
          <Flex gap="4" mr="auto" as={Link} to={`/profile/${post?.user?.id}`}>
            <Avatar
              name={post?.user?.fullName}
              src={post?.user?.profilePicURL}
            />
            <Text fontWeight={400} fontSize={18} size="sm">
              {post?.user?.fullName}
              <Text as="span" display="block" fontSize={14} color="muted">
                @{post?.user?.username}
              </Text>
            </Text>
          </Flex>
          <Menu>
            <MenuButton>
              <BsThreeDots size={25} color="#7C167D" />
            </MenuButton>
            <MenuList minW="0" overflow="hidden" p="0">
              {isAdmin && (
                <>
                  <MenuItem>
                    <IconButton
                      label="Edit Post"
                      Icon={<FiEdit2 color="#7C167D" size={18} />}
                    />
                  </MenuItem>
                  <MenuItem>
                    <IconButton
                      label="Delete Post"
                      onClick={() => deletePost(post?.id)}
                      Icon={<MdDelete color="#7C167D" size={20} />}
                    />
                  </MenuItem>
                </>
              )}
              <MenuItem>
                <IconButton
                  label="Report Post"
                  Icon={<MdReport color="#7C167D" size={20} />}
                />
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </CardHeader>
      <CardBody pb="0">
        {post?.type === 'text' && (
          <VStack
            minH="240px"
            bg="blackAlpha.500"
            rounded="22px"
            justify="center"
          >
            <Text color="white">{post?.text}</Text>
          </VStack>
        )}
        {post?.type === 'video' && (
          <>
            <Text mb="10px">{post?.text}</Text>
            <Plyr
              source={{
                type: 'video',
                sources: [
                  {
                    src: post?.media?.[0]?.captureFileURL || '',
                  },
                ],
              }}
            />
          </>
        )}
        {post?.type === 'image' && (
          <>
            <Text mb="10px">{post?.text}</Text>
            <Box bg="blackAlpha.500">
              <Link to={`/post-detail/${post?.id}`}>
                <Image
                  maxH="340px"
                  w="100%"
                  objectFit="contain"
                  src={post?.captureFileURL}
                />
              </Link>
            </Box>
          </>
        )}
        {post?.type === 'poll' && (
          <PollContent
            text={post?.text}
            poll={post?.poll}
            postID={post?.id}
            userID={post?.userID}
          />
        )}
        <HStack py="10px" justifyContent="space-between">
          <IconButton
            label="Like"
            onClick={() =>
              postDetails?.myReaction?.id
                ? post?.id && unLikePost(postDetails?.myReaction?.id)
                : likePost({ postID: post?.id, reactionName: 'like' })
            }
            Icon={
              <AiFillLike
                color={
                  postDetails?.myReaction?.id
                    ? 'var(--chakra-colors-primary-400)'
                    : ''
                }
                fontSize="28px"
              />
            }
          />
          <IconButton
            onClick={() => {
              onOpen()
              openComment()
            }}
            label="Comment"
            Icon={<FaRegComment fontSize="28px" />}
          />
          <IconButton
            label="Share"
            Icon={<FaTelegramPlane fontSize="28px" />}
          />
          <IconButton
            label="Save"
            onClick={() =>
              post?.isSaved
                ? unSavePost(post?.id)
                : savePost({ postID: post?.id })
            }
            Icon={
              <BsBookmarkDashFill
                color={post?.isSaved ? 'var(--chakra-colors-primary-400)' : ''}
                fontSize="28px"
              />
            }
          />
        </HStack>
      </CardBody>

      {/* Below component change on the based of type form API END */}
      <CardFooter display="block" pt="0px">
        <Text fontSize="16">
          {post?.reactionCount} likes
          <Text as="p" fontSize={16}>
            Liked by{' '}
            <Text as="span" fontWeight={600}>
              {post?.topReactions?.map(user => user?.fullName).join(' ')}
            </Text>
            {post?.topReactions?.[1] && (
              <>
                and{' '}
                <Text as="span" fontWeight={600}>
                  Others
                </Text>
              </>
            )}
          </Text>
          Mentioned users{' '}
          {post?.taggedUsersProfiles
            ?.map(tag => (
              <Text
                key={tag?.id}
                as="strong"
                fontWeight={600}
                color="primary.1000"
              >
                @{tag?.username}
              </Text>
            ))
            .join(' ')}
        </Text>
        <Text as="p" fontSize={14} color="muted">
          {diff(
            new Date(post?.postedDate || '').getTime(),
            new Date().getTime(),
          )}
          {/* {`${postedTime} ${postedTime > 0 ? 'days' : 'hours'}`} ago */}
        </Text>
        {allComments?.[1] && (
          <Button
            onClick={onOpen}
            variant="link"
            fontWeight="400"
            mt="15px"
            color="muted"
          >
            view all {allComments?.length} comments
          </Button>
        )}
        {isOpen &&
          post?.id &&
          allComments?.map(({ comment, myReaction }) => (
            <PostComment
              key={comment?.id}
              commentUser={comment?.userID}
              id={comment?.id}
              name={comment?.user?.fullName}
              date={comment?.commentedDate}
              likes={comment?.reactionCount}
              isLike={myReaction?.id}
              message={comment?.commentText}
              postId={post?.id}
              userImage={comment?.user?.profilePicURL}
              setReply={() => {
                setReply(true)
                setCommentId(comment?.id)
              }}
            />
          ))}
        {post?.id && isComments && (
          <UserPostComment
            commentId={commentId}
            setReply={() => setReply(false)}
            postID={post?.id}
            isReply={isReply}
          />
        )}
      </CardFooter>
    </Card>
  )
}
