import React, { Dispatch, SetStateAction } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
// import { useGetUsersByName } from 'utils/apis/user.api'

interface Props {
  statusText: string
  setStatusText: Dispatch<SetStateAction<string>>
}
export default function PostInput({ statusText, setStatusText }: Props) {
  const handleBlur = (a: string, b: boolean) => {
    console.log('blur', a, b)
  }

  //   const { data } = useGetUsersByName()
  return (
    <MentionsInput
      placeholder="write something.."
      style={{
        '&multiLine': {
          control: {
            fontFamily: 'HarmonyOS Sans',
            minHeight: 100,
            margin: '20px 0',
          },
          highlighter: {
            pointerEvents: 'none',
            zIndex: 1,
          },
          input: {
            outline: 'none',
          },
        },
        suggestions: {
          list: {
            backgroundColor: 'white',
            borderRadius: '8px',
            border: '1px solid #CBD5E0',
            padding: '5px',
          },
        },
      }}
      value={statusText}
      onChange={event => setStatusText(event?.target?.value)}
      onBlur={(event, clickedSuggestion) =>
        handleBlur(event?.target?.value, clickedSuggestion)
      }
    >
      <Mention
        appendSpaceOnAdd
        trigger={/(@(\w*))$/}
        data={[
          {
            id: 'iasdasdasdasd',
            display: 'Asad',
          },
          {
            id: 'gdfdfgsdfg',
            display: 'Fahad',
          },
        ]}
        style={{ color: '#7f29c4', fontWeight: 600, background: '#fff' }}
        displayTransform={(_, display) => {
          return `@${display}`
        }}
        renderSuggestion={(
          suggestion,
          search,
          highlightedDisplay,
          index,
          focused,
        ) => (
          <div className={`user ${focused ? 'focused' : ''}`}>
            @{highlightedDisplay}
          </div>
        )}
      />
    </MentionsInput>
  )
}
