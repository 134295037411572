import React from 'react'
import { Box, Button, Container, Flex, Text } from '@chakra-ui/react'
import DisplayNotification from 'components/displayNotification'
import { useNavigate } from 'react-router-dom'
import { notification } from 'utils/const'
import { useGetAllNotifications } from 'utils/apis/notifications.api'
interface Props {
  isPopUp?: boolean
}
export default function NotificationPopUp({ isPopUp }: Props) {
  const { data: notificationList } = useGetAllNotifications()
  console.log('notificationList', notificationList)
  const navigate = useNavigate()
  return (
    <Container maxW="100%">
      <Flex alignItems="center" justifyContent="space-between">
        <Text className="fs--16 fw--600">Notification</Text>
        <Button
          className="fs--12 fw--400"
          variant="link"
          _hover={{ textDecoration: 'none' }}
        >
          Mark all as read
        </Button>
      </Flex>
      <Text className="fs--12 fw--500" mb="15px">
        Today
      </Text>
      {notification.map(({ image, badgeImg, message, name, date }) => (
        <Box key={date}>
          <DisplayNotification
            name={name}
            message={message}
            time={date}
            image={image}
            badgeImage={badgeImg}
          />
        </Box>
      ))}
      {/* <Text className="fs--12 fw--500" mb="15px">
 Earlier
</Text> */}
      {isPopUp && (
        <Box textAlign="center">
          <Button
            className="fs--12 fw--500"
            variant="link"
            _hover={{ textDecoration: 'none' }}
            onClick={() => navigate('/notifications')}
          >
            See All
          </Button>
        </Box>
      )}
    </Container>
  )
}
