import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from 'index'
import { useNavigate } from 'react-router-dom'
import { useGetUserId } from 'utils/hooks'
import { CreateEvent, EventResponse } from 'utils/types/events.type'
import { client } from './api-client'

export const useGetAllEvents = (offset: number, limit: number) =>
  useQuery<EventResponse[], Error>(
    ['getAllEvents', { offset: offset, limit: limit }],
    () =>
      client(`event/getAllEventsWithAllData?offset=${offset}&limit=${limit}`),
  )

export const useGetEventHost = (id?: string) =>
  useQuery<EventResponse[], Error>(
    ['getEventHost', id],
    () => client(`event/getEventHost/${id}`),
    {
      enabled: !!id,
    },
  )

export const useGetEvent = (id?: string) =>
  useQuery<EventResponse, Error>(
    ['getEvent', id],
    () => client(`event/getEventByIdWithAllData/${id}`),
    {
      enabled: !!id,
    },
  )
export const useCreateEventStatus = () => {
  const userId = useGetUserId()
  return useMutation<unknown, Error, { statusType: string; eventID: string }>(
    data => {
      return client('event/createEventStatus', {
        data: { userID: userId, ...data },
      })
    },
    {
      onSuccess: (data, { eventID }) =>
        Promise.all([
          queryClient.invalidateQueries(['getEvent', eventID]),
          queryClient.invalidateQueries([
            'getAllEvents',
            { offset: 0, limit: 20 },
          ]),
        ]),
    },
  )
}

export const useUpdateEventStatus = () => {
  const userId = useGetUserId()
  return useMutation<
    unknown,
    Error,
    { statusType: string; eventID: string; id: string }
  >(
    data => {
      return client('event/updateEventStatus', {
        data: { userID: userId, ...data },
      })
    },
    {
      onSuccess: (data, { eventID }) =>
        Promise.all([
          queryClient.invalidateQueries(['getEvent', eventID]),
          queryClient.invalidateQueries([
            'getAllEvents',
            { offset: 0, limit: 20 },
          ]),
        ]),
    },
  )
}

export const useCreateEvent = () =>
  useMutation<unknown, Error, CreateEvent>(
    data => client(`event/addEvent`, { data }),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getAllEvents'])
      },
    },
  )

export const useUpdateEvent = () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useMutation<unknown, Error, any & { id: string }>(
    data => client(`event/updateEvent`, { data }),
    {
      onSuccess: (data, { id }) =>
        Promise.all([
          queryClient.invalidateQueries(['getEvent', id]),
          queryClient.invalidateQueries(['getAllEvents']),
        ]),
    },
  )

export const useGetEventsByName = (name: string, limit = 10) =>
  useQuery<EventResponse[], Error>(
    ['getEventsByName', name, limit],
    ({ signal }) =>
      client(`event/searchEventsWithAllData/${name}?offset=0&&limit=${limit}`, {
        signal,
      }),
  )

export const useDeleteEvent = () => {
  const navigate = useNavigate()
  return useMutation<unknown, Error, string>(
    id => client(`event/deleteEventById/${id}`),
    {
      onSuccess() {
        queryClient.invalidateQueries(['getAllEvents'])
        navigate('/events')
      },
    },
  )
}
