import React from 'react'
import Layout from 'layout'
import NotificationPopUp from 'components/notificationPopup'
import { Box } from '@chakra-ui/react'
import './notification.scss'
export default function Notification() {
  return (
    <Layout handleActions={() => console.log('e')}>
      <Box
        bg="white"
        padding="25px"
        border="1px solid rgba(191, 99, 197, 0.1)"
        rounded="16px"
      >
        <NotificationPopUp />
      </Box>
    </Layout>
  )
}
