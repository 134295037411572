import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Grid,
  Image,
  Input,
  Text,
  VisuallyHiddenInput,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BiImageAdd } from 'react-icons/bi'
import { MdOutlineCancel } from 'react-icons/md'
import { TbPhoto, TbSend, TbVideo } from 'react-icons/tb'
import PostInput from './postInput'
import IconButton from 'components/iconButton'
import { FileListItems } from 'utils/helper'
import { useGetUserId } from 'utils/hooks'
import { useUploadFile } from 'utils/apis/user.api'
import { useCreatePost } from 'utils/apis/post.api'
// import Plyr from 'plyr-react'

export default function MediaContent() {
  const [mediaFile, setMediaFile] = useState<FileList>()
  const [statusText, setStatusText] = useState<string>('')
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)

  const { mutate: createPost, isLoading } = useCreatePost()

  const handleClick = () => {
    if (inputRef) {
      inputRef.click()
    }
  }
  const handleChange = async (files: FileList | null) => {
    if (files) {
      const clientFiles = Object.values(files)
      const myFiles = Object.values(mediaFile || {}).concat(clientFiles)
      const list = FileListItems(myFiles)
      setMediaFile(list)
    }
  }

  const removeImage = (index: number) => {
    if (mediaFile?.length) {
      const myFiles = Object.values(mediaFile)
      myFiles.splice(index, 1) // make sure to use i-1, not i
      const list = FileListItems(myFiles)
      setMediaFile(list)
    }
  }
  const {
    mutate: uploadFile,
    data: imageString,
    isSuccess: imageUploaded,
    isLoading: imageUploading,
  } = useUploadFile()

  const handleCreatePost = async () => {
    if (mediaFile?.length) {
      uploadFile({
        folderName: 'post',
        field: 'timeline',
        file: Array.from(mediaFile)[0],
      })
    } else {
      createPost({
        postedTo: 'Club',
        userID: useGetUserId(),
        type: 'text',
        text: statusText,
      })
    }
  }

  useEffect(() => {
    if (imageUploaded) {
      createPost({
        postedTo: 'Club',
        userID: useGetUserId(),
        type: imageString?.mimetype.includes('image') ? 'image' : 'video',
        text: statusText,
        media: [
          {
            type: imageString?.mimetype.includes('image') ? 'image' : 'video',
            captureFileURL: imageString?.url,
            thumbnailURL: imageString?.url,
          },
        ],
      })
    }
  }, [imageString])
  console.log('uploadedData', imageString)

  return (
    <>
      <Box px="35px">
        <PostInput statusText={statusText} setStatusText={setStatusText} />
        <Box
          mb="25px"
          onDrop={e => handleChange(e.dataTransfer?.files)}
          onDragOver={e => e.preventDefault()}
          border="1px dashed #BF63C5"
          rounded="6px"
          bg="primary.100"
          overflow="hidden"
        >
          {mediaFile?.length ? (
            <Grid templateColumns="repeat(auto-fit, minmax(140px, 1fr))">
              {Object.values(mediaFile)?.map((image, index) => (
                <Box
                  key={image?.name}
                  position="relative"
                  maxH="300px"
                  minH="150px"
                  border="1px solid gray.400"
                >
                  <Box
                    position="absolute"
                    right="5px"
                    top="5px"
                    cursor="pointer"
                    onClick={() => removeImage(index)}
                    bg="error"
                    rounded="50%"
                    zIndex="2"
                  >
                    {' '}
                    <MdOutlineCancel color="#fff" fontSize="22px" />
                  </Box>
                  {image?.type.includes('image') ? (
                    <Image
                      src={URL.createObjectURL(image)}
                      objectFit="cover"
                      h="100%"
                      w="100%"
                      alt="Preview"
                    />
                  ) : (
                    // <Plyr
                    //   source={{
                    //     type: 'video',
                    //     sources: [
                    //       {
                    //         src: URL.createObjectURL(image),
                    //         type: image?.type,
                    //       },
                    //     ],
                    //   }}
                    // />
                    <video controls src={URL.createObjectURL(image)} />
                  )}
                </Box>
              ))}
            </Grid>
          ) : (
            <>
              <Flex gap="25px" p="25px" onClick={handleClick}>
                <IconButton
                  color="primary.500"
                  Icon={<BiImageAdd fontSize="33px" />}
                />
                <Text className="fs--14">
                  <Text as="strong" color="primary.500">
                    Drag & Drop{' '}
                  </Text>
                  file or{' '}
                  <Text as="strong" color="primary.500">
                    browse
                  </Text>{' '}
                  your computer, you can add more than one
                  <Text className="fs--12 fw--500">
                    Support .jpg, .png, .gif, .mp4 max 25mb
                  </Text>
                </Text>
              </Flex>
              <Button
                className="fs--16 fw--500"
                ml="18%"
                variant="outline"
                bg="white"
                mb="25px"
              >
                <Input
                  type="file"
                  border="0"
                  ref={input => setInputRef(input)}
                  multiple
                  onChange={e => handleChange(e.target.files)}
                  display="none"
                  accept="image/*,video/mp4"
                />
                Add Image or Video
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Flex alignItems="center" bg="gray.400">
        <FormLabel flex="1" textAlign="center" htmlFor="foPhoto" m="0">
          <IconButton
            label="Photo"
            onClick={handleClick}
            Icon={<TbPhoto fontSize="30px" />}
            color="white"
          />
          <VisuallyHiddenInput
            type="file"
            ref={input => setInputRef(input)}
            multiple
            onChange={e => handleChange(e.target.files)}
            accept="image/*,video/mp4"
            id="foPhoto"
          />
        </FormLabel>
        <Divider orientation="vertical" h="30px" />
        <FormLabel flex="1" textAlign="center" htmlFor="foPhoto" m="0">
          <IconButton
            label="Video"
            onClick={handleClick}
            Icon={<TbVideo fontSize="30px" />}
            color="white"
          />
          <VisuallyHiddenInput
            type="file"
            ref={input => setInputRef(input)}
            multiple
            onChange={e => handleChange(e.target.files)}
            accept="image/*,video/mp4"
            id="foPhoto"
          />
        </FormLabel>
        <Box p="8px 15px" bg="#6374A0">
          <IconButton
            isLoading={isLoading || imageUploading}
            onClick={handleCreatePost}
            Icon={<TbSend fontSize="30px" />}
            color="white"
          />
        </Box>
      </Flex>
    </>
  )
}
