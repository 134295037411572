import React from 'react'
import { Box, Container, Flex } from '@chakra-ui/react'
import Header from 'components/header'
import ProfileCard from 'components/profileCard'
import ExploreCard from 'components/exploreCard'
import SuggestionsCard from 'components/suggestionsCard'
import ManageGroupCard from 'components/manageGroupCard'
import EventAction from 'components/eventActionCard'
import { useLocation } from 'react-router-dom'

interface Props {
  children?: JSX.Element
  handleActions: (arg: string) => void
}

export default function Layout({ children, handleActions }: Props) {
  const { pathname } = useLocation()
  return (
    <>
      <Header />
      <Container maxW="1280" pt="40px">
        <Flex>
          <Box maxW="250">
            <Box position="sticky" top="-400px">
              <ProfileCard />
              <ExploreCard />
              {pathname.includes('group-details') && (
                <ManageGroupCard handleActions={handleActions} />
              )}
              {pathname.includes('event-details') && (
                <EventAction handleActions={handleActions} />
              )}
              {!(
                pathname.includes('group-details') ||
                pathname.includes('event-details')
              ) && (
                <>
                  <SuggestionsCard title="Groups" goto="/groups" />
                  <SuggestionsCard title="Events" isEvents goto="/events" />
                </>
              )}
            </Box>
          </Box>
          <Box px="20px" flex={1}>
            {children}
          </Box>
          {!pathname.includes('watch-now') && (
            <Box maxW="280">
              <Box position="sticky" top="20px">
                <SuggestionsCard
                  title="People may you know"
                  isFollow
                  goto="/explore"
                />
              </Box>
            </Box>
          )}
        </Flex>
      </Container>
    </>
  )
}
