import React from 'react'
import {
  List,
  ListItem,
  Card,
  CardBody,
  Avatar,
  Button,
  CardHeader,
  Heading,
  Divider,
  Text,
  Box,
} from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useGetSuggestedUsers } from 'utils/apis/user.api'

const exploreMore = [
  {
    label: 'Watch now',
    url: '',
    image: '',
  },
  {
    label: 'Saved Posts',
    url: '',
    image: '',
  },
  {
    label: 'Events',
    url: '',
    image: '',
  },
  {
    label: 'Sponsors',
    url: '',
    image: '',
  },
]

interface Props {
  title: string
  isFollow?: boolean
  isEvents?: boolean
  goto: string
}
export default function SuggestionsCard({
  isFollow,
  title,
  isEvents,
  goto,
}: Props) {
  const { data: userList } = useGetSuggestedUsers()

  return (
    <Card mb="20px" bg="white" variant="primary">
      <CardHeader pb="0">
        <Heading fontSize="18px" as="h6">
          {title}
        </Heading>
      </CardHeader>
      <CardBody>
        <List spacing={3}>
          {isEvents
            ? exploreMore?.map(({ label }) => (
                <DisplayItem to={`event/${2123123}`} name={label} key={label} />
              ))
            : userList?.map(({ fullName, id, username, profilePicURL }) => (
                <DisplayItem
                  to={`/profile/${id}`}
                  name={fullName}
                  username={username}
                  isFollow={isFollow}
                  picture={profilePicURL}
                  key={id}
                />
              ))}
        </List>
      </CardBody>
      <Divider />
      <Button
        as={Link}
        to={goto}
        w="100%"
        my="0.5rem"
        variant="ghost"
        fontWeight="400"
      >
        See All
      </Button>
    </Card>
  )
}

function DisplayItem({
  isFollow,
  name,
  username,
  picture,
  to,
}: {
  name: string
  to: string
  username?: string
  url?: string
  picture?: string
  isEvents?: boolean
  isFollow?: boolean
}) {
  return (
    <ListItem>
      <Box as={Link} to={to} display="flex" gap="10px" alignItems="center">
        <Avatar boxSize="40px" src={picture} />
        <Text fontWeight="400">
          {name}
          {isFollow && (
            <Text fontSize="14px" color="muted" display="block">
              {username}
            </Text>
          )}
        </Text>
        {isFollow && (
          <Button ml="auto" variant="outline" size="xs">
            Follow
          </Button>
        )}
      </Box>
    </ListItem>
  )
}
