import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Image,
  Avatar,
  Text,
  Heading,
  Flex,
} from '@chakra-ui/react'
import Placeholder from 'assets/images/bg-placeholder.png'
import { useGetCacheData, useGetUserId } from 'utils/hooks/index'
import { UserProfile } from 'utils/types/user.type'

export default function ProfileCard() {
  const profileData = useGetCacheData<UserProfile>([
    'getUserProfile',
    useGetUserId(),
  ])

  return (
    <Card mb="20px" bg="white" variant="primary">
      <Image objectFit="cover" src={Placeholder} alt="wallpaper placeholder" />
      <CardHeader p="0" textAlign="center" mt="-35px">
        <Avatar
          width="70px"
          height="70px"
          name={profileData?.fullName}
          src={profileData?.profilePicURL}
        />
      </CardHeader>
      <CardBody textAlign="center" pt="0.6rem">
        <Heading fontSize="18px" as="h6">
          {profileData?.fullName}
        </Heading>
        <Text color="muted">@{profileData?.username}</Text>
        <Flex justifyContent="space-evenly" mt="15px">
          <Text>
            Following
            <Text display="block" as="span">
              {profileData?.followingCount}
            </Text>
          </Text>
          <Text>
            Following
            <Text display="block" as="span">
              {profileData?.followersCount}
            </Text>
          </Text>
        </Flex>
      </CardBody>
    </Card>
  )
}
