import React, { useMemo, useState } from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
} from '@chakra-ui/react'
import coverImg from 'assets/images/group/groupCoverImg.png'
import { BsCameraFill } from 'react-icons/bs'
import FileUploader from 'components/fileUploadCard'
import { useForm } from 'react-hook-form'
import { CreateGroupType, Group } from 'utils/types/groups.type'
import { useGetAllUsers, useUploadFile } from '../../../utils/apis/user.api'
import { useFilterUsersByName } from 'utils/hooks'
import { checkUserAdded } from '../../../utils/helper'
import { useCreateGroup, useUpdateGroup } from 'utils/apis/groups.api'
import { useEffect } from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  groupDetails?: Group
}
export default function CreateGroup({ isOpen, onClose, groupDetails }: Props) {
  const [image, setImage] = useState<File>()
  const [searchText, setSearchText] = useState('')

  const {
    mutate: uploadFile,
    data: imageString,
    isSuccess: imageUploaded,
    isLoading: imageUploading,
  } = useUploadFile()

  const { mutate: createGroup, isLoading, isSuccess } = useCreateGroup()
  const {
    mutate: updateGroup,
    isLoading: isUpdating,
    isSuccess: isUpdated,
  } = useUpdateGroup()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateGroupType>()
  const { data: allUsers } = useGetAllUsers(0, 10)
  const admins = watch('admins')
  const captureImageURL = watch('captureImageURL')

  const filteredUsers = useMemo(
    () => useFilterUsersByName(allUsers || [], searchText),
    [allUsers, searchText],
  )

  const handleAdmins = (id: string) => {
    if (admins && checkUserAdded(admins, id)) {
      setValue(
        'admins',
        admins?.filter(item => item !== id),
      )
    } else {
      if (admins?.length) setValue('admins', [...admins, id])
      else setValue('admins', [id])
    }
  }

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event?.target?.files) {
      setImage(event?.target?.files[0])
      setValue('captureImageURL', URL.createObjectURL(event?.target?.files[0]))
    }
  }

  const onSubmit = (data: CreateGroupType) => {
    if (image) {
      uploadFile({ folderName: 'group', field: 'banner', file: image })
    } else {
      handleCreateGroup(data)
    }
  }

  const handleCloseModal = () => {
    reset()
    onClose()
  }

  const handleCreateGroup = (data?: CreateGroupType) => {
    const groupPayload = getValues()
    const payload = data || {
      ...groupDetails,
      ...groupPayload,
      ...(imageString?.url && { captureImageURL: imageString?.url }),
    }

    if (groupDetails) {
      updateGroup({ ...groupDetails, ...payload })
    } else {
      createGroup(payload)
    }
  }

  useEffect(() => {
    if (isSuccess || isUpdated) handleCloseModal()
  }, [isSuccess, isUpdated])

  useEffect(() => {
    if (imageUploaded) handleCreateGroup()
  }, [imageUploaded])

  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal} isCentered size="xl">
      <ModalOverlay />
      <ModalContent rounded="18px" overflow="hidden">
        <ModalHeader p="0" position="relative">
          <Image
            src={captureImageURL || groupDetails?.captureImageURL || coverImg}
            w="100%"
            maxH="200px"
            minH="200px"
            objectFit="cover"
          />
          <Box
            position="absolute"
            bottom="10px"
            left="12px"
            bg="blackAlpha.600"
            rounded="8px"
          >
            <FileUploader
              handleFile={handleFile}
              icon={<BsCameraFill />}
              text="Add Cover Image"
            />
          </Box>
        </ModalHeader>

        <ModalBody p="0">
          <Box as="form" onSubmit={handleSubmit(onSubmit)} p="25px 43.5px">
            <Text className="fs--16 fw--600">Create Group</Text>
            <Text className="fs--12 fw--400" mt="15px" mb="5px">
              Title
            </Text>
            <FormControl isInvalid={Boolean(errors?.groupName)}>
              <Input
                placeholder="Group title"
                className="fs--12"
                defaultValue={groupDetails?.groupName}
                {...register('groupName', {
                  required: 'Please enter your Group Title name.',
                })}
              />
              {errors?.groupName && (
                <FormErrorMessage>
                  {errors?.groupName?.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <Text className="fs--12 fw--400" mt="15px" mb="5px">
              Description
            </Text>
            <FormControl isInvalid={Boolean(errors?.description)}>
              <Textarea
                className="fs--12"
                defaultValue={groupDetails?.description}
                {...register('description', {
                  required: 'Please enter Description.',
                })}
                placeholder="Description..."
              />
              {errors?.description && (
                <FormErrorMessage>
                  {errors?.description?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <Accordion allowToggle>
              <AccordionItem border="0">
                <AccordionButton _hover={{}} px="0">
                  <Box as="span" flex="1" textAlign="left" className="fs--12">
                    Moderator
                    <AvatarGroup size="xs" max={4} mt="5px">
                      {allUsers?.map(({ fullName, profilePicURL, id }) => {
                        if (
                          admins?.indexOf(id) !== undefined &&
                          admins?.indexOf(id) >= 0
                        )
                          return (
                            <Avatar
                              key={id}
                              name={fullName}
                              src={profilePicURL}
                            />
                          )
                      })}
                    </AvatarGroup>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel
                  mt="10px"
                  p="15px"
                  rounded="5px"
                  border=" 1px solid rgba(102, 102, 102, 0.1)"
                >
                  <Input
                    variant="unstyled"
                    placeholder="Search user..."
                    mb="10px"
                    value={searchText}
                    onChange={e =>
                      setSearchText(e?.target?.value?.toLocaleLowerCase())
                    }
                  />
                  <Divider />
                  <Container h="270px" overflowY="scroll" overflowX="hidden">
                    {filteredUsers?.map(({ fullName, profilePicURL, id }) => (
                      <Flex
                        gap="10px"
                        sx={{ '&:last-child': { border: 'none' } }}
                        borderBottom=" 1px solid rgba(102, 102, 102, 0.1)"
                        key={id}
                        alignItems="center"
                        py="10px"
                      >
                        <Avatar size="sm" name={fullName} src={profilePicURL} />
                        <Text flex="1">{fullName}</Text>
                        <Button
                          variant="outline"
                          size="xs"
                          onClick={() => handleAdmins(id)}
                        >
                          {admins && checkUserAdded(admins, id)
                            ? 'Remove'
                            : 'Add'}
                        </Button>
                      </Flex>
                    ))}
                  </Container>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Divider />
            <ButtonGroup justifyContent="center" w="100%" my="25px">
              <Button
                className="fs--16 fw--500"
                type="button"
                variant="outline"
                onClick={handleCloseModal}
                w="100px"
              >
                cancel
              </Button>
              <Button
                isLoading={isLoading || imageUploading || isUpdating}
                className="fs--16 fw--500"
                type="submit"
              >
                {groupDetails ? 'Update Group' : 'Create Group'}
              </Button>
            </ButtonGroup>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
