import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import PrivateRoute from 'routes/privateRoute'
import Landing from 'views/landing'
import Notification from 'views/notification'
import Groups from 'views/groups'
import GroupDetails from 'views/groupDetails'
import EventsTimeLine from 'views/eventsTimeLine'
import EventDetail from 'views/eventDetail'
import SavePost from 'views/savePost'
import Explore from 'views/explore'
import WatchNow from 'views/watchNow'
import EditProfile from 'views/editProfile'
import Profile from 'views/profile'
import Sponsors from 'views/sponsors'
import PostDetails from 'views/postDetails'
const routes = [
  {
    path: '/landing',
    Component: Landing,
  },
  {
    path: '/post-detail/:id',
    Component: PostDetails,
  },
  {
    path: '/notifications',
    Component: Notification,
  },
  {
    path: '/groups',
    Component: Groups,
  },
  {
    path: '/group-details/:id',
    Component: GroupDetails,
  },
  {
    path: '/events',
    Component: EventsTimeLine,
  },
  {
    path: '/explore',
    Component: Explore,
  },
  {
    path: '/event-details/:id',
    Component: EventDetail,
  },
  {
    path: '/saved-posts',
    Component: SavePost,
  },
  {
    path: '/watch-now',
    Component: WatchNow,
  },
  {
    path: '/profile',
    Component: Profile,
  },
  {
    path: '/profile/:id',
    Component: Profile,
  },
  {
    path: '/edit-profile',
    Component: EditProfile,
  },
  {
    path: '/sponsors',
    Component: Sponsors,
  },
]
export default function AppRoutes() {
  return (
    <Routes>
      {routes.map(({ path, Component }) => (
        <Route
          key={path}
          path={path}
          element={
            <PrivateRoute>
              <Component />
            </PrivateRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/landing" />} />
    </Routes>
  )
}
