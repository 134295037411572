import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  List,
} from '@chakra-ui/react'
import { HiUserAdd, HiUsers } from 'react-icons/hi'
import { FaUserShield } from 'react-icons/fa'
import { MdDelete, MdModeEditOutline } from 'react-icons/md'
import IconButton from 'components/iconButton'
import { useLocation } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'

const style = { color: '#6374A0', fontSize: '24px' }
const manageGroupCardAdmin = [
  {
    label: 'Manage Requests',
    icon: <HiUserAdd style={style} />,
    path: 'manageRequest',
  },
  {
    label: 'Users',
    icon: <HiUsers style={style} />,

    subLabel: [
      { label: 'Add User', path: 'addUser' },
      { label: 'Remove User', path: 'removeUsers' },
    ],
  },
  {
    label: 'Moderator',
    icon: <FaUserShield style={style} />,

    subLabel: [
      { label: 'Make Moderator', path: 'makeModerator' },
      { label: 'Remove Moderator', path: 'removeModerator' },
    ],
  },
  {
    label: 'Delete Group',
    icon: <MdDelete style={style} />,
    path: 'deleteGroup',
  },
  {
    label: 'Edit Group',
    icon: <MdModeEditOutline style={style} />,
    path: 'editGroup',
  },
]
const manageGroupCardUser = [
  {
    label: 'View Users',
    icon: <HiUsers style={style} />,
    path: 'Users List',
  },
  {
    label: 'View Moderator',
    icon: <FaUserShield style={style} />,
    path: 'Moderators',
  },
  {
    label: 'Leave Group',
    icon: <FiLogOut style={style} />,
    path: 'leaveGroup',
  },
]
export default function ManageGroupCard({
  handleActions,
}: {
  handleActions: (arg: string) => void
}) {
  const { state } = useLocation()

  return (
    <Card mb="20px" bg="white">
      <CardBody>
        <List spacing={3}>
          {state?.status === 'admin' ? (
            <Accordion allowToggle defaultIndex={[0]}>
              {manageGroupCardAdmin?.map(({ icon, label, path, subLabel }) =>
                subLabel ? (
                  <AccordionItem key={path} border="0">
                    <AccordionButton p="0" m="0">
                      <IconButton label={label} Icon={icon} color="text" />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Flex alignItems="center" gap="7px">
                        <Box h="45px">
                          <Divider
                            orientation="vertical"
                            opacity="1"
                            borderLeftWidth="2px"
                            borderLeftColor="gray.500"
                          />
                        </Box>
                        <Box>
                          {subLabel?.map(({ label, path }) => (
                            <Box key={label}>
                              <IconButton
                                onClick={() => handleActions(path)}
                                label={label}
                                color="text"
                              />
                            </Box>
                          ))}
                        </Box>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                ) : (
                  <IconButton
                    label={label}
                    key={path}
                    Icon={icon}
                    color="text"
                    onClick={() => handleActions(path)}
                  />
                ),
              )}
            </Accordion>
          ) : (
            <>
              {manageGroupCardUser?.map(({ label, icon, path }) => (
                <IconButton
                  key={path}
                  label={label}
                  Icon={icon}
                  color="text"
                  onClick={() => handleActions(path)}
                />
              ))}
            </>
          )}
        </List>
      </CardBody>
    </Card>
  )
}
