import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGetUserId } from 'utils/hooks'
import {
  EditProfileType,
  UserProfile,
  UploadFileType,
  UploadedFileResponse,
} from 'utils/types/user.type'
import { client } from './api-client'

export const useGetProfile = (id: string) =>
  useQuery<UserProfile, Error>(['getUserProfile', id], () =>
    client(`profile/getProfileById/${id}`),
  )

export const useGetAllUsers = (offset: number, limit: number) =>
  useQuery<UserProfile[], Error>(['getUserList'], () =>
    client(`profile/getAllUsers?offset=${offset}&limit=${limit}`),
  )

export const useGetSuggestedUsers = () =>
  useQuery<UserProfile[], Error>(['getSuggestedUsers'], () =>
    client(`profile/getSuggestedPeople?offset=3&limit=3`),
  )

export const useGetFollowerUsers = (
  offset: number,
  limit: number,
  id?: string,
) =>
  useQuery<UserProfile[], Error>(
    ['getFollowerList', id],
    () => client(`profile/getFollowers/${id}?offset=${offset}&limit=${limit}`),
    {
      enabled: !!id,
    },
  )

export const useGetFollowingUsers = (
  offset: number,
  limit: number,
  id?: string,
) =>
  useQuery<UserProfile[], Error>(
    ['getFollowingList', id],
    () => client(`profile/getFollowing/${id}?offset=${offset}&limit=${limit}`),
    {
      enabled: !!id,
    },
  )

export const useEditProfile = () => {
  const queryClient = useQueryClient()
  return useMutation<UserProfile, Error, EditProfileType>(
    data => client(`profile/editProfile`, { data }),
    {
      onSuccess: () => queryClient.invalidateQueries(['getUserProfile']),
    },
  )
}
export const useUploadFile = () =>
  useMutation<UploadedFileResponse, Error, UploadFileType>(data => {
    const formData = new FormData()
    formData.append('file', data?.file)
    return client(
      `media-upload/mediaFiles/${data?.folderName}/${data?.field}`,
      {
        data: formData,
      },
    )
  })

export const useGetUsersByName = (name: string, limit = 10) =>
  useQuery<UserProfile[], Error>(
    ['getUsersByName', name, limit],
    ({ signal }) =>
      client(`profile/searchProfileByName/${name}?offset=0&&limit=${limit}`, {
        signal,
      }),
  )

export const useFollowUser = () => {
  const userId = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<UserProfile, Error, string>(
    followerId => client(`profile/getfollowUser/${userId}/${followerId}`),
    {
      onSuccess: () => {
        // Promise.all([
        queryClient.invalidateQueries(['getUsersByName'])
        queryClient.invalidateQueries(['getUserProfile', userId])
        // ])
      },
    },
  )
}

export const useUnFollowUser = () => {
  const userId = useGetUserId()
  return useMutation<UserProfile, Error, string>(followerId =>
    client(`profile/unfollowUser/${followerId}/${userId}`),
  )
}

export const useUpdatePassword = () => {
  const userId = useGetUserId()
  return useMutation<UserProfile, Error, string>(password =>
    client(`profile/changePassword/${userId}`, { data: { password } }),
  )
}
