import React from 'react'
import { Button } from '@chakra-ui/react'

interface Props {
  label?: string | JSX.Element
  Icon?: JSX.Element
  fontSize?: string
  fontWeight?: string
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  onClick?: () => void
  color?: string
}
export default function IconButton({
  label,
  Icon,
  fontSize,
  isLoading,
  type,
  color,
  fontWeight,
  onClick,
}: Props) {
  return (
    <Button
      type={type}
      isLoading={isLoading}
      fontSize={fontSize}
      color={color || 'muted'}
      fontWeight={fontWeight || '400'}
      variant="link"
      p="4px"
      _hover={{
        textDecoration: 'none',
      }}
      leftIcon={Icon}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}
