import React, { useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  HStack,
  FormControl,
  InputRightElement,
  InputGroup,
  InputLeftElement,
  Divider,
  Input,
} from '@chakra-ui/react'
import { UserReplies } from 'utils/types/post.type'
import { MdDelete, MdSend } from 'react-icons/md'
import { useForm } from 'react-hook-form'
import { UserProfile } from 'utils/types/user.type'
import { useGetCacheData, useGetUserId } from 'utils/hooks'
import {
  useDeleteComment,
  useGetCommentReplies,
  useLikeComment,
  usePostComment,
  usePostReply,
  useUnLikeComment,
} from 'utils/apis/post.api'
import { diff } from 'utils/helper'
import IconButton from 'components/iconButton'
import { FiEdit2 } from 'react-icons/fi'
import { BsThreeDots } from 'react-icons/bs'
import { AiFillLike } from 'react-icons/ai'

export function PostComment({
  name,
  date,
  likes,
  message,
  postId,
  id,
  userImage,
  isLike,
  commentUser,
  setReply,
}: UserReplies) {
  const { isOpen, onOpen } = useDisclosure()
  const { mutate: likeComment } = useLikeComment()
  const { mutate: unLikeComment } = useUnLikeComment()
  const { mutate: deleteComment } = useDeleteComment()
  const { data: replies } = useGetCommentReplies(id)
  return (
    <>
      <Flex mt="25px" gap="15px" position="relative">
        <Divider
          opacity="1"
          border="1.5px solid #edf2f7"
          orientation="vertical"
          top="0px"
          position="absolute"
          left="3.7%"
        />
        <Avatar name={name} src={userImage} />
        <Box flex="1">
          <Box rounded="10px" p="15px" bg="gray.100" flex="1">
            <Flex mb={11} w="100%" border="0px">
              <Text as="p" mr="auto" fontWeight="600">
                {name}
                <Text as="span" color="muted" ml="10px" fontSize="12px">
                  {diff(new Date(date || '').getTime(), new Date().getTime())}
                </Text>
              </Text>
              {commentUser === useGetUserId() && (
                <Menu>
                  <MenuButton>
                    <BsThreeDots size={25} color="#7C167D" />
                  </MenuButton>
                  <MenuList minW="0" p="0" borderRadius="8px" overflow="hidden">
                    <MenuItem>
                      <IconButton
                        color="text"
                        label="Edit"
                        onClick={() => deleteComment(id)}
                        Icon={<FiEdit2 color="#7C167D" size={20} />}
                      />
                    </MenuItem>
                    <MenuItem>
                      <IconButton
                        color="text"
                        label="Delete"
                        onClick={() => deleteComment(id)}
                        Icon={<MdDelete color="#7C167D" size={20} />}
                      />
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Flex>
            <Text as="p" color="muted" pb="12px">
              {message}
            </Text>
            <HStack fontSize="12px" gap="10px">
              <IconButton
                fontSize="14px"
                label={`${likes}`}
                onClick={() =>
                  isLike
                    ? unLikeComment(isLike)
                    : likeComment({
                        postID: postId,
                        commentID: id,
                        reactionName: 'like',
                      })
                }
                Icon={
                  <AiFillLike
                    color={isLike ? 'var(--chakra-colors-primary-400)' : ''}
                    fontSize="20px"
                  />
                }
              />
              <IconButton onClick={setReply} fontSize="14px" label="Reply" />
            </HStack>
          </Box>
          {replies?.[1] && (
            <>
              <Button onClick={onOpen} mt="13px" variant="link">
                View more replies {replies?.length}
              </Button>
              {isOpen &&
                replies?.length &&
                replies?.map(reply => (
                  <PostComment
                    key={reply?.id}
                    postId={postId}
                    id={reply?.id}
                    name={reply?.user?.fullName}
                    date={reply?.commentedDate}
                    likes={reply?.reactionCount}
                    isLike={''}
                    message={reply?.commentText}
                    userImage={reply?.user?.profilePicURL}
                    commentUser={reply?.user?.id}
                  />
                ))}
            </>
          )}
        </Box>
      </Flex>
    </>
  )
}

export function UserPostComment({
  postID,
  isReply,
  commentId,
  setReply,
}: {
  commentId?: string
  postID: string
  isReply: boolean
  setReply: () => void
}) {
  const { mutate: postComment, isLoading, isSuccess } = usePostComment()
  const {
    mutate: postReply,
    isLoading: isReplying,
    isSuccess: replySuccess,
  } = usePostReply()

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<{ commentText: string }>()

  function onSubmit(values: { commentText: string }) {
    if (isReply && commentId)
      postReply({ postID, replyToCommentID: commentId, ...values })
    else postComment({ postID, ...values })
  }
  useEffect(() => {
    if (isSuccess || replySuccess) {
      reset()
      setReply()
    }
  }, [isSuccess, replySuccess])
  const userProfile = useGetCacheData<UserProfile>([
    'getUserProfile',
    useGetUserId(),
  ])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl mt="15px" bg="background" isInvalid={!!errors.commentText}>
        <InputGroup>
          <InputLeftElement
            mt="3px"
            ml="0.5em"
            // eslint-disable-next-line react/no-children-prop
            children={
              <Avatar
                src={userProfile?.profilePicURL}
                name={userProfile?.fullName}
                size="sm"
              />
            }
          />
          <Input
            size="lg"
            pl="3em"
            focusBorderColor="primary.500"
            id="message"
            placeholder={isReply ? 'Reply' : 'Comment'}
            {...register('commentText', {
              required: 'Message is required',
            })}
          />
          <InputRightElement
            mt="3px"
            // eslint-disable-next-line react/no-children-prop
            children={
              <IconButton
                type="submit"
                isLoading={isLoading || isReplying}
                Icon={<MdSend fontSize="28px" />}
              />
            }
          />
        </InputGroup>
      </FormControl>
    </form>
  )
}
