import React from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react'
// import { manageGroup } from 'utils/data/manageGroup.data'
import { Link } from 'react-router-dom'
import { addSpaceInWords } from 'utils/helper'
import { UserProfile } from 'utils/types/user.type'
import {
  useAddUserToGroup,
  useApproveUserRequest,
  useGetGroupUsers,
  useGetModerators,
  useGetRequestedUsers,
  useRemoveModerator,
  useRemoveUser,
  useGetGroupSuggestedUsers,
} from 'utils/apis/groups.api'

export default function GroupManageUsers({
  type,
  groupId,
}: {
  type: string
  groupId?: string
}) {
  const { data: groupModerators } = useGetModerators(groupId)
  const { data: allUsers } = useGetGroupSuggestedUsers(groupId)
  const { data: groupUsers } = useGetGroupUsers(groupId)
  const { data: requestedUsers } = useGetRequestedUsers(groupId)
  return (
    <Box mt="15px" p="25px 56px" rounded="10px" bg="white" boxShadow="base">
      <Text className="fs--16 fw--500" mb="20px">
        {addSpaceInWords(type)}
      </Text>
      <InputGroup mb="25px" bg="white">
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.300" />
        </InputLeftElement>

        <Input
          type="tel"
          placeholder="Search users to add..."
          fontSize="12px"
        />
      </InputGroup>

      {type === 'manageRequest' &&
        groupId &&
        requestedUsers?.map(request => (
          <DisplayUser
            key={request?.id}
            groupId={groupId}
            user={request?.user}
            isRequests
          />
        ))}
      {type === 'removeModerator' &&
        groupId &&
        groupModerators?.map(moderator => (
          <DisplayUser
            key={moderator?.id}
            type={type}
            groupId={groupId}
            user={moderator?.user}
          />
        ))}
      {(type === 'makeModerator' || type === 'addUser') &&
        groupId &&
        allUsers?.map(user => (
          <DisplayUser
            groupId={groupId}
            type={type}
            key={user?.id}
            user={user}
            isAdd
          />
        ))}
      {type === 'removeUsers' &&
        groupId &&
        groupUsers?.map(addedUSer => (
          <DisplayUser
            key={addedUSer?.id}
            groupId={groupId}
            user={addedUSer?.user}
          />
        ))}
    </Box>
  )
}

function DisplayUser({
  isAdd,
  isRequests,
  user,
  type,
  groupId,
}: {
  isAdd?: boolean
  isRequests?: boolean
  type?: string
  groupId: string
  user: UserProfile
}) {
  const { mutate: addUserToGroup, isLoading: addLoading } = useAddUserToGroup()
  const { mutate: approveRequest } = useApproveUserRequest()
  const { mutate: removeUser, isLoading: removeLoading } = useRemoveUser()
  const { mutate: removeModerator, isLoading: removeModLoading } =
    useRemoveModerator()
  const userInfo = { userId: user?.id, groupId: groupId }
  const addUser = {
    userID: user?.id,
    statusType: type === 'addUser' ? 'approved' : 'admin',
    groupID: groupId,
  }
  return (
    <Flex alignItems="center" gap="10px" mb="1rem">
      <Avatar size="sm" name={user?.fullName} src={user?.profilePicURL} />
      <Flex
        flex={1}
        alignItems="center"
        gap="7px"
        borderBottom="1px solid #e4e4e6"
        pb="10px"
      >
        <Text flex={1} className="fs--14 ">
          {user?.fullName}
        </Text>
        {isRequests ? (
          <>
            <Button
              size="sm"
              variant="link"
              textDecoration="underline"
              onClick={() => removeUser(userInfo)}
            >
              Ignore
            </Button>
            <Button
              onClick={() => approveRequest(userInfo)}
              size="sm"
              variant="outline"
            >
              Approve
            </Button>
          </>
        ) : (
          <>
            {isAdd && (
              <Button
                as={Link}
                size="sm"
                to={{ pathname: '/profile', search: user?.id }}
                variant="link"
              >
                View Profile
              </Button>
            )}
            <Button
              size="sm"
              variant="outline"
              isLoading={addLoading || removeLoading || removeModLoading}
              onClick={() =>
                isAdd
                  ? addUserToGroup(addUser)
                  : type === 'removeModerator'
                  ? removeModerator(userInfo)
                  : removeUser(userInfo)
              }
            >
              {isAdd ? 'Add' : 'Remove'}
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  )
}
