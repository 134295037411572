import React, { useState, useEffect } from 'react'
import Layout from 'layout'
import {
  Box,
  Image,
  Flex,
  Avatar,
  Heading,
  Text,
  Card,
  CardHeader,
  CardBody,
  Grid,
  Tooltip,
} from '@chakra-ui/react'
import { BsFillEyeFill, BsDot } from 'react-icons/bs'
import Plyr from 'plyr-react'
import { useGetYoutubeVideos } from 'utils/apis/watch-now'
import { Video } from 'utils/types/watch-now'

export default function WatchNow() {
  const { data: videos } = useGetYoutubeVideos()
  const [currentVideo, setCurrentVideo] = useState<Video>()

  useEffect(() => {
    if (videos?.length)
      setTimeout(() => {
        setCurrentVideo({ ...videos[0] })
      }, 1000)
  }, [videos])

  return (
    <Layout handleActions={() => console.log('e')}>
      <>
        <Flex gap="20px">
          <VideoPlayer video={currentVideo} />
          <Card
            bg="white"
            rounded="21px"
            maxW="280px"
            minW="250px"
            mr="-20px"
            maxH="590px"
          >
            <CardHeader pb="0">
              <Heading size="sm">Recommended</Heading>
            </CardHeader>
            <CardBody overflow="auto">
              {videos?.slice(9, 14)?.map(video => (
                <RecommendVideo
                  key={video?.title}
                  video={video}
                  setCurrentVideo={setCurrentVideo}
                />
              ))}
            </CardBody>
          </Card>
        </Flex>
        <Card bg="white" mt="20px" rounded="21px" mr="-20px">
          <CardHeader pb="0">
            <Heading size="md">Featured</Heading>
          </CardHeader>
          <CardBody as={Flex} gap="20px">
            {videos?.slice(0, 3)?.map(video => (
              <SuggestedVideo
                key={video?.title}
                video={video}
                setCurrentVideo={setCurrentVideo}
              />
            ))}
          </CardBody>
        </Card>
        <Card bg="white" mt="20px" rounded="21px" mr="-20px">
          <CardHeader pb="0">
            <Heading size="md">Most watched</Heading>
          </CardHeader>
          <CardBody
            as={Grid}
            gridTemplateColumns="repeat(auto-fit, minmax(278px, 1fr))"
            gap="15px"
          >
            {videos?.slice(3, 9)?.map(video => (
              <SuggestedVideo
                key={video?.title}
                video={video}
                setCurrentVideo={setCurrentVideo}
              />
            ))}
          </CardBody>
        </Card>
      </>
    </Layout>
  )
}

function VideoPlayer({ video }: { video?: Video }) {
  return (
    <Box>
      <Box
        overflow="hidden"
        mb="15px"
        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
        borderRadius="21px"
      >
        <Plyr
          source={{
            type: 'video',
            sources: [
              {
                src: video?.captureFileURL || '',
                provider: 'youtube',
              },
            ],
          }}
        />
      </Box>
      <Heading as="h3" size="md">
        {video?.title}
      </Heading>
      <Flex
        maxW="220px"
        as="p"
        fontSize="14px"
        align="center"
        justifyContent="space-between"
        color="primary.400"
        my="10px"
      >
        <Flex align="center">
          <BsFillEyeFill />
          <Text as="span" ml="5px">
            11,100
          </Text>
        </Flex>
        <Flex as="span" align="center">
          <BsDot fontSize="24px" />3 days ago
        </Flex>
      </Flex>
      <Flex flex="1" gap="4">
        <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
        <Box flex="1">
          <Heading size="sm">Segun Adebayo</Heading>
          <Text color="muted" fontSize="16px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida
            vitae pharetra ipsum tellus quisque. Sed mollis vel integer in amet.
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

function RecommendVideo({
  video,
  setCurrentVideo,
}: {
  video: Video
  setCurrentVideo: React.Dispatch<React.SetStateAction<Video | undefined>>
}) {
  return (
    <Flex mb="20px" onClick={() => setCurrentVideo(video)}>
      <Image
        rounded="13px"
        objectFit="cover"
        maxW="120px"
        src={video?.thumbnailURL}
        alt="Caffe Latte"
      />
      <Box pl="10px">
        <Tooltip label={video?.title} placement="auto">
          <Heading size="xs" noOfLines={1}>
            {video?.title}
          </Heading>
        </Tooltip>
        <Text fontSize="14px" my="10px" noOfLines={1}>
          Caffè latte is a coffee beverage of Italian origin made with espresso
          and steamed milk.
        </Text>
        <Flex
          as="p"
          fontSize="12px"
          align="center"
          justifyContent="space-between"
        >
          5:20{' '}
          <Flex as="span" align="center">
            <BsDot fontSize="24px" />3 days ago
          </Flex>
        </Flex>
      </Box>
    </Flex>
  )
}

function SuggestedVideo({
  video,
  setCurrentVideo,
}: {
  video: Video
  setCurrentVideo: React.Dispatch<React.SetStateAction<Video | undefined>>
}) {
  return (
    <Box cursor="pointer" onClick={() => setCurrentVideo(video)}>
      <Image
        rounded="21px"
        objectFit="cover"
        src={video?.thumbnailURL}
        alt={video?.title}
      />
      <Flex mt="15px">
        <Flex flex="1" gap="4" alignItems="center">
          <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
          <Box flex="1">
            <Heading fontSize="md">Segun Adebayo</Heading>
            <Text color="muted" fontSize="14px" noOfLines={1}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Text>
          </Box>
        </Flex>
        <Flex lineHeight="1" fontSize="14px" color="primary.400">
          <BsFillEyeFill />
          <Text as="span" ml="5px">
            11,100
          </Text>
        </Flex>
      </Flex>
    </Box>
  )
}
