import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Input,
  Text,
} from '@chakra-ui/react'
import Header from 'components/header'
import { BsUpload } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'
import { UserProfile } from 'utils/types/user.type'
import IconButton from 'components/iconButton/index'
import {
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import noPostImg from 'assets/images/noPostImg.svg'
import { BsHandThumbsUp } from 'react-icons/bs'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useGetUserPosts } from 'utils/apis/post.api'
// import { useGetUserId } from 'utils/hooks'

import './myPosts.scss'
import { FaRegComment } from 'react-icons/fa'
import { EditIcon } from '@chakra-ui/icons'
import { MdDelete } from 'react-icons/md'
import { Post } from 'utils/types/post.type'
import {
  useGetFollowerUsers,
  useGetFollowingUsers,
  useGetProfile,
} from 'utils/apis/user.api'
import { useGetUserId } from 'utils/hooks'

export default function Profile() {
  const [activeContent, setActiveContent] = useState<
    'post' | 'followers' | 'following'
  >('post')
  const navigate = useNavigate()
  const { id } = useParams()

  const userId = id || useGetUserId()
  const isAdmin = useGetUserId() === userId
  const { data: profileData } = useGetProfile(userId)
  const { data: postData } = useGetUserPosts(1, 20, userId)
  const { data: followerUsers } = useGetFollowerUsers(1, 20, userId)
  const { data: followingUsers } = useGetFollowingUsers(1, 20, userId)
  return (
    <>
      <Header />
      <Container maxW="1313" pt="40px">
        <Box textAlign="center">
          <Avatar
            p="3px"
            size="xl"
            name={profileData?.fullName}
            src={profileData?.profilePicURL}
            border="1px solid #7C167D"
          />
          <Text as="span" display="block" className="fs--20 fw--500">
            {profileData?.fullName}
          </Text>
          <Text as="span" className="fs--14 " color="muted" mb="15px">
            @{profileData?.username}
          </Text>
          {isAdmin && (
            <Flex my="30px" justify="center" gap="10px">
              <Button
                className="fs--14 fw--500"
                onClick={() => navigate('/edit-profile')}
              >
                Edit Profile
              </Button>
              <IconButton aria-label="Search database" Icon={<BsUpload />} />
            </Flex>
          )}
          <Flex justify="center" alignItems="center" gap="35px">
            <IconButton
              onClick={() => setActiveContent('post')}
              label={
                <Box>
                  <Text
                    as="span"
                    display="block"
                    mb="6px"
                    className="fs--16 fw--700"
                  >
                    Posts
                  </Text>
                  <Text as="span" color="text" className="fs--16 fw--500">
                    {postData?.length}
                  </Text>
                </Box>
              }
            />
            <Box h="30px">
              <Divider borderColor="primary.300" orientation="vertical" />
            </Box>

            <IconButton
              onClick={() => setActiveContent('following')}
              label={
                <Box>
                  <Text
                    as="span"
                    display="block"
                    mb="6px"
                    className="fs--16 fw--700"
                  >
                    Following
                  </Text>
                  <Text as="span" color="text" className="fs--16 fw--500">
                    {profileData?.followingCount}
                  </Text>
                </Box>
              }
            />
            <Box h="30px">
              <Divider borderColor="primary.300" orientation="vertical" />
            </Box>
            <IconButton
              onClick={() => setActiveContent('followers')}
              label={
                <Box>
                  <Text
                    as="span"
                    display="block"
                    mb="6px"
                    className="fs--16 fw--700"
                  >
                    Followers
                  </Text>
                  <Text as="span" color="text" className="fs--16 fw--500">
                    {profileData?.followersCount}
                  </Text>
                </Box>
              }
            />
          </Flex>
        </Box>

        {activeContent === 'post' && postData && <MyPosts myPosts={postData} />}
        {(activeContent === 'following' || activeContent === 'followers') && (
          <Container mt="25px" p="25px 110px" maxW="840px">
            <Input
              type="tel"
              placeholder="Search..."
              fontSize="12px"
              variant="flushed"
              mb="10px"
            />

            {activeContent === 'following'
              ? followingUsers?.map(user => (
                  <User user={user} key={user?.id} isFollow />
                ))
              : followerUsers?.map(user => <User user={user} key={user?.id} />)}
          </Container>
        )}
      </Container>
    </>
  )
}

function User({ isFollow, user }: { isFollow?: boolean; user: UserProfile }) {
  return (
    <Flex
      alignItems="center"
      gap="10px"
      py="10px"
      borderBottom="1px solid #e4e4e6"
    >
      <Avatar size="sm" name={user?.fullName} src={user?.profilePicURL} />
      <Text flex={1} className="fs--14 ">
        {user?.fullName}
      </Text>
      <Button size="sm" variant="outline">
        {isFollow ? 'Unfollow' : 'follow'}
      </Button>
    </Flex>
  )
}

function MyPosts({ myPosts }: { myPosts: Post[] }) {
  return (
    <Box p="40px 0 60px">
      {myPosts?.length ? (
        <Grid templateColumns="repeat(auto-fit, minmax(240px, 1fr))" gap="20px">
          {myPosts?.map(post => (
            <Box w="100%" maxW="300px" position="relative" key={post?.id}>
              {post?.type === 'text' ? (
                <Text minH="240px">{post?.text}</Text>
              ) : post?.type === 'video' ? (
                <video
                  height="100%"
                  controls
                  src={post?.media?.[0]?.captureFileURL}
                />
              ) : (
                <Image
                  objectFit="cover"
                  h="100%"
                  src={post?.media[0]?.captureFileURL}
                />
              )}
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <Button
                    variant="link"
                    _hover={{ textDecoration: 'none' }}
                    position="absolute"
                    right="8px"
                    top="15px"
                  >
                    <BiDotsVerticalRounded color="white" />
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent w="153px">
                    <PopoverArrow />

                    <PopoverBody>
                      <Button
                        leftIcon={<EditIcon />}
                        variant="link"
                        _hover={{ textDecoration: 'none' }}
                        className="fs--14 fw--500"
                        mb="15px"
                      >
                        Edit Post
                      </Button>
                      <Button
                        className="fs--14 fw--500"
                        variant="link"
                        _hover={{ textDecoration: 'none' }}
                        leftIcon={<MdDelete />}
                      >
                        Delete Post
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>

              <Box
                position="absolute"
                bottom="0"
                w="100%"
                as={Flex}
                justifyContent="space-around"
                bg="blackAlpha.500"
              >
                <IconButton
                  color="white"
                  label={post?.reactionCount?.toString()}
                  Icon={<BsHandThumbsUp />}
                />
                <IconButton
                  color="white"
                  label={post?.commentsCount?.toString()}
                  Icon={<FaRegComment />}
                />
              </Box>
            </Box>
          ))}
        </Grid>
      ) : (
        <Box textAlign="center" w="100%">
          <Image src={noPostImg} mx="auto" mt="30px" />
          <Text className="fs--32 fw--600" mt="15px" color="muted">
            No Posts
          </Text>
        </Box>
      )}
    </Box>
  )
}
