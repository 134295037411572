import React from 'react'
import {
  Box,
  Text,
  HStack,
  RadioGroup,
  Radio,
  Stack,
  Progress,
} from '@chakra-ui/react'
import { useAddPoll } from 'utils/apis/post.api'
import { checkUserAdded } from 'utils/helper'

export function PollContent({
  text,
  poll,
  userID,
  postID,
}: {
  text: string
  poll: any
  userID: string
  postID: string
}) {
  const { mutate: castVote } = useAddPoll()
  const votes: number[] = []
  if (poll && poll.totalVotesCount) {
    votes.push(0)
    votes.push((poll?.votes1?.length / poll?.totalVotesCount) * 100)
    votes.push((poll?.votes2?.length / poll?.totalVotesCount) * 100)
    votes.push((poll?.votes3?.length / poll?.totalVotesCount) * 100)
    votes.push((poll?.votes4?.length / poll?.totalVotesCount) * 100)
  }
  return (
    <>
      {' '}
      <Text mb="10px">{text}</Text>
      {checkUserAdded(poll?.votes1, userID) ||
      checkUserAdded(poll?.votes2, userID) ||
      checkUserAdded(poll?.votes3, userID) ||
      checkUserAdded(poll?.votes4, userID) ? (
        <>
          {Object.keys(poll)?.map((option, index) => {
            if (option?.includes('choice')) {
              return (
                <Box my="5px" key={index}>
                  <HStack justifyContent="space-between" mb="8px">
                    <Text className="fs--12 fw--400">{poll[option]}</Text>
                    <Text className="fs--12 fw--400">
                      {votes[Number(option?.split('choice')[1])]}%
                    </Text>{' '}
                  </HStack>
                  <Progress
                    value={votes[Number(option?.split('choice')[1])]}
                    size="xs"
                  />
                </Box>
              )
            }
          })}
        </>
      ) : (
        <RadioGroup>
          <Stack>
            {Object.keys(poll)?.map((option, index) => {
              if (option?.includes('choice')) {
                return (
                  <Radio
                    value={option?.split('choice')[1]}
                    key={index}
                    onChange={e =>
                      castVote({
                        postID,
                        choiceNumber: e.target.value,
                      })
                    }
                  >
                    {poll[option]}
                  </Radio>
                )
              }
            })}
          </Stack>
        </RadioGroup>
      )}
    </>
  )
}
