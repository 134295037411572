import React from 'react'
import { Card, CardBody, List, ListItem } from '@chakra-ui/react'
import { MdOutlineIosShare, MdModeEditOutline, MdDelete } from 'react-icons/md'
import IconButton from 'components/iconButton'

const style = { color: '#6374A0', fontSize: '24px' }

export default function EventAction({
  handleActions,
}: {
  handleActions: (arg: string) => void
}) {
  return (
    <Card mb="20px" bg="white">
      <CardBody>
        <List spacing={3}>
          <ListItem cursor="pointer">
            <IconButton
              label=" Share Event"
              Icon={<MdOutlineIosShare style={style} />}
              onClick={() => handleActions('share')}
            />
          </ListItem>
          <ListItem cursor="pointer">
            <IconButton
              onClick={() => handleActions('edit')}
              Icon={<MdModeEditOutline style={style} />}
              label=" Edit Event"
            />
          </ListItem>
          <ListItem cursor="pointer">
            <IconButton
              onClick={() => handleActions('delete')}
              Icon={<MdDelete style={style} />}
              label=" Delete Event"
            />
          </ListItem>
        </List>
      </CardBody>
    </Card>
  )
}
