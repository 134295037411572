import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools'

export default extendTheme(
  {
    styles: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      global: (props: StyleFunctionProps | Record<string, any>) => ({
        body: {
          bg: mode('#FAFAFA', '#2d3748')(props),
        },
      }),
    },
    fonts: {
      body: 'HarmonyOS Sans',
      text: 'HarmonyOS Sans',
      heading: 'HarmonyOS Sans',
    },
    colors: {
      primary: {
        main: '#924ece',
        50: 'rgba(191, 99, 197, 0.05)',
        100: '#d8c3ed',
        200: '#bf9be2',
        300: '#a670d7',
        400: '#924ece',
        500: '#7f29c4',
        600: '#7523be',
        700: '#6819b6',
        800: '#5c10af',
        900: '#4900a1',
        1000: '#7C167D',
        1001: '#64748B', //for comment box text color
      },
      secondary: '#E7CEFC',
      text: '#0F001C',
      background: '#f7f8f9',
      error: '#E84A4A',
      success: '#4ECA78',
      muted: '#7B7382',
      grayAlpha: 'rgba(99, 116, 160, 0.1)',
    },
    components: {
      Text: {
        baseStyle: {
          color: mode('#52525B', 'red.500'),
        },
      },
      Card: {
        variants: {
          primary: {
            container: {
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
              borderRadius: '18px',
            },
          },
        },
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'primary',
  }),
)
