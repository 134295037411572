import React, { useState } from 'react'
import Layout from 'layout'
import {
  Avatar,
  Box,
  Button,
  Card,
  Center,
  Flex,
  Grid,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { Search2Icon } from '@chakra-ui/icons'

import { MdGroup } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { useGetUsersByName } from 'utils/apis/user.api'
import { useGetGroupsByName, useJoinGroup } from 'utils/apis/groups.api'
import { Link } from 'react-router-dom'
import { useFollowUser } from '../../utils/apis/user.api'
import { Event } from 'utils/types/events.type'
import { useGetEventsByName } from 'utils/apis/events.api'
import { useGetUserId } from 'utils/hooks'
import notFound from 'assets/images/no-explore.png'

export default function Explore() {
  const [search, setSearch] = useState('%20')
  const userId = useGetUserId()

  const { data: userList, isLoading: userLoading } = useGetUsersByName(
    search,
    6,
  )
  const { data: groupList, isLoading: groupLoading } = useGetGroupsByName(
    search,
    4,
  )
  const { data: eventList, isLoading: eventLoading } = useGetEventsByName(
    search,
    2,
  )

  const handleSearch = (text: string) => {
    setSearch(text || '%20')
  }
  return (
    <Layout handleActions={() => console.log('e')}>
      <>
        <InputGroup
          mb="25px"
          bg="white"
          boxShadow="0px 2px 10px rgba(0, 0, 0, 0.15)"
          rounded="6px"
        >
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>

          <Input
            type="text"
            placeholder="Search User, Groups, Event"
            focusBorderColor="#DDE2E4"
            borderColor="#DDE2E4"
            onChange={e => handleSearch(e?.target?.value)}
          />
        </InputGroup>
        {eventList?.length || userList?.length || groupList?.length ? (
          <>
            <Heading as="h2" fontSize="22px" mb="25px">
              Users
            </Heading>
            <Grid
              gap="20px"
              templateColumns="repeat(auto-fill, minmax(190px, 1fr))"
            >
              {userList?.length ? (
                userList?.map(user => (
                  <ExploreCard
                    key={user?.id}
                    id={user?.id}
                    fullName={user?.fullName}
                    username={user?.username}
                    image={user?.profilePicURL}
                  />
                ))
              ) : userLoading ? (
                <Spinner />
              ) : (
                <Text fontWeight="800">Sorry! No user found {`:(`}</Text>
              )}
            </Grid>
            <Heading as="h2" fontSize="22px" mb="25px" mt="35px">
              Groups
            </Heading>
            <Grid
              gap="20px"
              templateColumns="repeat(auto-fill, minmax(150px, 1fr))"
            >
              {groupList?.length ? (
                <>
                  {groupList?.map(group => (
                    <ExploreCard
                      fullName={group?.groupName}
                      key={group?.id}
                      id={group?.id}
                      image={group?.captureImageURL}
                      status={userId === group?.user?.id ? 'admin' : 'user'}
                    />
                  ))}

                  <Center
                    gridColumn="1/5"
                    className="fs--16 fw--500"
                    color="text"
                    pt="25px"
                    as={Link}
                    to="/groups"
                  >
                    See More
                  </Center>
                </>
              ) : groupLoading ? (
                <Spinner />
              ) : (
                <Text gridColumn="1/5" fontWeight="800">
                  Sorry! No group found {`:(`}
                </Text>
              )}
            </Grid>
            <Heading as="h2" fontSize="22px" mb="25px" mt="35px">
              Events
            </Heading>
            <Grid gap="10px" templateColumns="1fr" pb="30px">
              {eventList?.length ? (
                <>
                  {eventList?.map(({ event }) => (
                    <ExploreEventCard key={event?.id} event={event} />
                  ))}
                  <Center
                    as={Link}
                    to="/events"
                    className="fs--16 fw--500"
                    color="text"
                    py="25px"
                    fontWeight="600"
                  >
                    See More
                  </Center>
                </>
              ) : eventLoading ? (
                <Spinner />
              ) : (
                <Text gridColumn="1/4" fontWeight="800">
                  Sorry! No event found {`:(`}
                </Text>
              )}
            </Grid>
          </>
        ) : userLoading || eventLoading || groupLoading ? (
          <Spinner />
        ) : (
          <Center flexDirection="column">
            <Text fontSize="24px" mt="60px" fontWeight="900">
              Sorry! No result found {`:(`}
            </Text>
            <Image w="50%" mt="40px" src={notFound} />
          </Center>
        )}
      </>
    </Layout>
  )
}

function ExploreEventCard({ event }: { event: Event }) {
  return (
    <Card
      p="25px"
      bg="white"
      variant="primary"
      as={Link}
      to={`/event-details/${event?.id}`}
    >
      <Text className="fs--16 fw--600" mb="8px">
        {event?.eventName}
      </Text>
      <Flex alignItems="center" gap="10px" justify="space-between">
        <Box>
          <Text
            className="fs--14 fw--400"
            color="muted"
            display="flex"
            gap="10px"
            mb="8px"
          >
            <IconButton
              isRound
              bg="#5B8FF4"
              size="xs"
              aria-label="time"
              icon={<AiOutlineClockCircle fontSize="18px" />}
            />{' '}
            {`${new Date(event?.startDate)?.toDateString()} at ${new Date(
              event?.startDate,
            )?.toLocaleTimeString()}`}
          </Text>
          <Text
            className="fs--14 fw--400"
            color="muted"
            mb="13px"
            display="flex"
            gap="10px"
          >
            <IconButton
              isRound
              bg="gray.400"
              size="xs"
              aria-label="groups"
              icon={<MdGroup fontSize="16px" />}
            />
            {event?.interestedCount} Interested - {event?.goingCount} Attending
          </Text>
          <Button
            as={Link}
            to={`/event-details/${event?.id}`}
            className="fs--13 fw--500"
            variant="outline"
            mr="10px"
            size="sm"
            w="90px"
          >
            view
          </Button>
          {/* <Button className="fs--13 fw--500" size="sm" >
                Attend
              </Button> */}
        </Box>
        <Avatar
          size="xl"
          name={event?.eventName}
          src={event?.captureImageURL}
        />
      </Flex>
    </Card>
  )
}

function ExploreCard({
  fullName,
  username,
  image,
  id,
  status,
}: {
  fullName: string
  username?: string
  image: string
  id: string
  status?: string
}) {
  const { mutate: joinGroup, isLoading } = useJoinGroup()
  const { mutate: followUser, isLoading: followLoading } = useFollowUser()

  return (
    <Card bg="white" textAlign="center" p="15px" variant="primary">
      <Link
        to={username ? `/profile/${id}` : `/group-details/${id}`}
        state={{
          groupId: id,
          status: status,
        }}
      >
        <Avatar size="xl" name={fullName} src={image} mx="auto" />
        <Text className="fs--16 fw--600" color="text" mt="15px">
          {fullName}
        </Text>
        {username && (
          <Text className="fs--14 fw--400" color="muted">
            @{username}
          </Text>
        )}
      </Link>
      <Button
        isLoading={isLoading || followLoading}
        onClick={() => (username ? followUser(id) : joinGroup(id))}
        variant="outline"
        size="sm"
        w="96px"
        mx="auto"
        mt="15px"
      >
        {username ? 'Follow' : 'Join'}
      </Button>
    </Card>
  )
}
