import React from 'react'
import {
  Box,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Checkbox,
  HStack,
} from '@chakra-ui/react'
import { BsPlus } from 'react-icons/bs'
import { MdOutlineCancel } from 'react-icons/md'
import { useFieldArray, useForm } from 'react-hook-form'
import { useCreatePost } from 'utils/apis/post.api'
import { useGetUserId } from 'utils/hooks'

interface Props {
  onOpenDisc: React.Dispatch<React.SetStateAction<boolean>>
}

type MyValues = {
  text: string
  options: Record<string, unknown>[]
  endDate: string
}

export default function PollContent({ onOpenDisc }: Props) {
  const { mutate: createPost, isLoading } = useCreatePost()

  const { handleSubmit, register, control } = useForm<MyValues>({
    defaultValues: { options: [{ choice1: '' }, { choice2: '' }], endDate: '' },
  })
  const { fields, append, remove } = useFieldArray({
    name: 'options', // unique name for your Field Array
    control,
  })
  const onSubmit = (values: MyValues) => {
    createPost({
      text: values?.text,
      poll: Object.assign(
        {
          expiryDate: values?.endDate,
          votes1: [],
          votes2: [],
          votes3: [],
          votes4: [],
        },
        ...values.options.map(item => item),
      ),
      type: 'poll',
      userID: useGetUserId(),
      postedTo: 'Club',
    })
  }
  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} p="30px 40px">
      <Input
        variant="outline"
        {...register('text', {
          required: 'Question is required',
        })}
        placeholder="Type your question here"
        className="fs--14 fw--400"
        focusBorderColor="primary.400"
      />
      {fields.map((field, index) => (
        <InputGroup my="10px" key={field?.id}>
          <Input
            focusBorderColor="primary.400"
            // name={opt}
            {...register(`options.${index}.choice${index + 1}`, {
              required: `Choice${index + 1} is required`,
            })}
            placeholder={`Choice${index + 1}`}
            className="fs--14 fw--400"
          />
          {index > 1 && (
            <InputRightElement>
              <MdOutlineCancel color="red" onClick={() => remove(index)} />
            </InputRightElement>
          )}
        </InputGroup>
      ))}
      <Button
        leftIcon={<BsPlus />}
        onClick={() => append({ [`choice${fields?.length + 1}`]: '' })}
        variant="ghost"
        className="fs--16 fw--500"
        mb="33px"
      >
        Add More
      </Button>
      <br />
      <Text as="label" className="fs--16 fw--500">
        Poll End Date
      </Text>
      <Input
        focusBorderColor="primary.400"
        type="datetime-local"
        mt="10px"
        {...register(`endDate`, { required: `endDate is required` })}
      />
      <HStack my="25px" spacing="20px">
        <Checkbox>Public poll result</Checkbox>
        <Checkbox>Hide participants from each other</Checkbox>
      </HStack>
      <Box textAlign="center">
        <Button
          className="fs--16 fw--500"
          w="182px"
          variant="outline"
          mr={3}
          onClick={() => onOpenDisc(true)}
        >
          Close
        </Button>
        <Button
          isLoading={isLoading}
          type="submit"
          className="fs--16 fw--500"
          w="182px"
        >
          Create Poll
        </Button>
      </Box>
    </Box>
  )
}
