import React from 'react'
import Layout from 'layout'
import PostCard from 'components/postCard'
import { useGetPost } from 'utils/apis/post.api'
import { useParams } from 'react-router-dom'

export default function PostDetails() {
  const { id } = useParams()
  const { data } = useGetPost(id)
  return (
    <Layout handleActions={() => console.log('call')}>
      <PostCard postDetails={data} />
    </Layout>
  )
}
