import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGetUserId } from 'utils/hooks'
import {
  CreatePost,
  LikeComment,
  Post,
  PostComment,
  PostReaction,
  SavedPost,
  SavePost,
  CommentResponse,
  Comment,
  PostResponse,
  PostReply,
} from 'utils/types/post.type'
import { client } from './api-client'

export const useGetAllPost = () =>
  useQuery<PostResponse[], Error>(['getAllPosts'], () =>
    client(`post/getAllPostsWithAllData/Club?isPollRequired=true`),
  )

export const useGetUserPosts = (offset: number, limit: number, id?: string) =>
  useQuery<Post[], Error>(
    ['getUserPosts', id],
    () => client(`post/getPostByUserId/${id}?offset=${offset}&limit=${limit}`),
    {
      enabled: !!id,
    },
  )

export const useSavePost = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<Post, Error, SavePost>(
    data => client(`save-post/addSavePost`, { data: { userID, ...data } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllPosts'])
        queryClient.invalidateQueries(['getUserSavedPosts'])
        queryClient.invalidateQueries(['getPost'])
      },
    },
  )
}

export const useGetSavedPosts = (offset: number, limit: number, id?: string) =>
  useQuery<SavedPost[], Error>(
    ['getUserSavedPosts'],
    () =>
      client(
        `save-post/getSavePostByUserId/${id}?offset=${offset}&limit=${limit}`,
      ),
    {
      enabled: !!id,
    },
  )

export const useUnSavePost = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()

  return useMutation<Post, Error, string>(
    postID => client(`save-post/deleteSavePost/${userID}/${postID}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getAllPosts'])
        queryClient.invalidateQueries(['getUserSavedPosts'])
        queryClient.invalidateQueries(['getPost'])
      },
    },
  )
}

export const useGetPost = (postID?: string) =>
  useQuery<PostResponse, Error>(['getPost', postID], () =>
    client(`post/getPostByIdWithAllData/${postID}`),
  )

export const useDeletePost = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, string>(
    postID => client(`post/deletePost/${postID}`),
    {
      onSuccess: () => queryClient.invalidateQueries(['getAllPosts']),
    },
  )
}

export const useCreatePost = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, CreatePost>(
    data => client('post/AddPost', { data }),
    {
      onSuccess: () => queryClient.invalidateQueries(['getAllPosts']),
    },
  )
}

export const usePostLike = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, PostReaction>(
    data =>
      client('post-reaction/addPostReaction', { data: { userID, ...data } }),
    {
      onSuccess: () => queryClient.invalidateQueries(['getAllPosts']),
    },
  )
}

export const usePostUnLike = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, string>(
    id => client(`post-reaction/deletePostReaction/${id}`),
    {
      onSuccess: () => queryClient.invalidateQueries(['getAllPosts']),
    },
  )
}
export const usePostComment = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, PostComment>(
    data => client(`comment/addComment`, { data: { userID, ...data } }),
    {
      onSuccess: (data, { postID }) =>
        queryClient.invalidateQueries(['getComments', postID]),
    },
  )
}

export const useGetComments = (postId?: string) => {
  return useQuery<CommentResponse[], Error>(
    ['getComments', postId],
    () => client(`comment/getCommentByIdWithAllData/${postId}`),
    {
      enabled: !!postId,
    },
  )
}

export const useLikeComment = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, LikeComment>(
    data =>
      client(`comments-reaction/addReaction`, { data: { userID, ...data } }),
    {
      onSuccess: (data, { postID }) =>
        Promise.all([queryClient.invalidateQueries(['getComments', postID])]),
    },
  )
}

export const useUnLikeComment = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, string>(
    id => client(`comments-reaction/deleteCommentReaction/${id}`),
    {
      onSuccess: (data, id) =>
        queryClient.invalidateQueries(['getComments', id]),
    },
  )
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, string>(
    id => client(`comment/deleteCommentById/${id}`),
    {
      onSuccess: (data, id) =>
        queryClient.invalidateQueries(['getComments', id]),
    },
  )
}

export const usePostReply = () => {
  const userID = useGetUserId()
  const queryClient = useQueryClient()

  return useMutation<unknown, Error, PostReply>(
    data =>
      client(`comment-reply/addCommentReply`, { data: { userID, ...data } }),
    {
      onSuccess: (data, { postID }) =>
        queryClient.invalidateQueries(['getComments', postID]),
    },
  )
}

export const useGetCommentReplies = (id?: string) =>
  useQuery<Comment[], Error>(
    ['getCommentReplies'],
    () => client(`comment-reply/getCommentReply/${id}`),
    {
      enabled: !!id,
    },
  )
export const useAddPoll = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, Error, { postID?: string; choiceNumber: string }>(
    ({ postID, choiceNumber }) =>
      client(`post/addVote/${postID}/${choiceNumber}`),
    {
      onSuccess: () => queryClient.invalidateQueries(['getAllPosts']),
    },
  )
}
