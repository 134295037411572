export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export function checkUserAdded(addedArr: string[], id: string) {
  return addedArr?.filter(item => item === id)?.length
}

export function addSpaceInWords(arg: string) {
  const string = arg.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function diff(t1: number, t2: number) {
  const diff = Math.max(t1, t2) - Math.min(t1, t2)
  const SEC = 1000,
    MIN = 60 * SEC,
    HRS = 60 * MIN

  const hrs = Math.floor(diff / HRS)
  const min = Math.floor((diff % HRS) / MIN).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })
  const sec = Math.floor((diff % MIN) / SEC).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
  })
  if (hrs > 24) return new Date(t1).toDateString()
  else if (hrs) return `${hrs} hr ${min} mins ago`
  else if (min) return `${min} mins ago`
  else if (sec) return `${sec} sec ago`
  else return new Date(t1).toDateString()
}

export function FileListItems(files: File[]) {
  const b = new ClipboardEvent('').clipboardData || new DataTransfer()
  for (let i = 0, len = files.length; i < len; i++) b.items.add(files[i])
  return b.files
}
