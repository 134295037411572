import { client } from './api-client'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  NotificationsStatus,
  UserNotifications,
} from 'utils/types/notification.type'
import { useGetUserId } from 'utils/hooks'
import { queryClient } from 'index'

export const useGetAllNotifications = () =>
  useQuery<unknown, Error>(['getAllNotifications'], () =>
    client(`notification/getUserNotifications/Club`),
  )

export const useSetViewNotification = () =>
  useMutation<unknown, Error, string>(id =>
    client(`notification/viewNotification/${id}`),
  )
export const useGetNotificationStatus = () =>
  useQuery<NotificationsStatus, Error>(['getNotifiStatus'], () =>
    client(`user-devices/getDeviceById/${useGetUserId()}`),
  )

export const useUpdataNotificationStatus = () => {
  const userId = useGetUserId()
  return useMutation<unknown, Error, NotificationsStatus>(
    status => {
      return client(`user-devices/updateDevice`, {
        data: { id: userId, ...status },
      })
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(['getNotifiStatus'])
      },
    },
  )
}

export const useGetUserNotifications = (
  type: string,
  offset: number,
  limit: number,
) =>
  useQuery<UserNotifications, Error>(
    ['userNotifications', { type: type, offset: offset, limit: limit }],
    () =>
      client(
        `notification/getUserNotifications/${type}?offset=${0}&limit=${5}`,
      ),
  )
