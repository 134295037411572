import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VisuallyHiddenInput,
} from '@chakra-ui/react'
import Header from 'components/header'
import React, { useState, useEffect } from 'react'
import { BsPerson } from 'react-icons/bs'
import { FiBell, FiLock } from 'react-icons/fi'
import { CiEdit } from 'react-icons/ci'
import { TfiHelpAlt } from 'react-icons/tfi'
import { BiLockAlt } from 'react-icons/bi'
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from 'react-icons/hi'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { frequentlyAskQuestion } from 'utils/data/freqAskQuestion.data'
import IconButton from 'components/iconButton'
import { useLocation } from 'react-router-dom'
import { EditProfileType, UpdatePasswordForm } from 'utils/types/user.type'
import { useForm } from 'react-hook-form'
import {
  useEditProfile,
  useGetProfile,
  useUpdatePassword,
  useUploadFile,
} from 'utils/apis/user.api'
import { useGetUserId } from 'utils/hooks'
import {
  useGetNotificationStatus,
  useUpdataNotificationStatus,
} from 'utils/apis/notifications.api'
export default function EditProfile() {
  const [editProfile, setEditProfile] = useState<boolean>(false)
  const { state } = useLocation()
  return (
    <>
      <Header />
      <Container maxW="1280px">
        <Tabs
          variant="unstyled"
          colorScheme="green"
          orientation="vertical"
          defaultIndex={state?.path === 'notification' ? 2 : 0}
        >
          <TabList px="18px" mt="44px" maxW="240px" w="100%">
            <Text className="fs--18 fw--700" mb="12px">
              Account{' '}
            </Text>
            <Tab
              p="6px 15px"
              justifyContent="start"
              _selected={{
                color: 'white',
                bg: 'primary.400',
                rounded: '7px',
              }}
              textAlign="end"
            >
              <IconButton
                label=" Profile"
                color="inherit"
                Icon={<BsPerson fontSize="18px" />}
              />
            </Tab>
            <Tab
              p="6px 15px"
              justifyContent="start"
              _selected={{
                color: 'white',
                bg: 'primary.400',
                rounded: '7px',
              }}
              textAlign="end"
            >
              <IconButton
                label=" Password"
                color="inherit"
                Icon={<BiLockAlt fontSize="18px" />}
              />
            </Tab>
            <Tab
              p="6px 15px"
              justifyContent="start"
              _selected={{
                color: 'white',
                bg: 'primary.400',
                rounded: '7px',
              }}
              textAlign="end"
            >
              <IconButton
                label=" Notification"
                color="inherit"
                Icon={<FiBell fontSize="18px" />}
              />
            </Tab>
            <Text className="fs--18 fw--700" my="12px">
              More{' '}
            </Text>
            <Tab
              p="6px 15px"
              justifyContent="start"
              _selected={{
                color: 'white',
                bg: 'primary.400',
                rounded: '7px',
              }}
              textAlign="end"
            >
              <IconButton
                label=" Help"
                color="inherit"
                Icon={<TfiHelpAlt fontSize="18px" />}
              />
            </Tab>
          </TabList>
          <TabPanels borderLeft="1px solid #EDEDED">
            <TabPanel px="33px" mt="20px">
              <Text className="fs--28 fw--600">Profile</Text>
              <Flex
                alignItems="center"
                justify="space-between"
                borderBottom="1px solid gray"
                pb="13px"
              >
                <Text className="fs--14 fw--400" color="muted">
                  Edit your user profile
                </Text>
                <Button
                  leftIcon={<CiEdit fontSize="18px" />}
                  className="fs--14 fw--500"
                  variant="outline"
                  onClick={() => {
                    setEditProfile(true)
                  }}
                >
                  Edit Profile
                </Button>
              </Flex>
              <MyProfile isEdit={editProfile} setEditProfile={setEditProfile} />
            </TabPanel>
            <TabPanel px="33px" mt="20px">
              <Text className="fs--28 fw--600">Change Password</Text>
              <Text
                className="fs--14 fw--400"
                borderBottom="1px solid gray"
                py="13px"
              >
                Please make sure the new password is not your old password
              </Text>
              <ChangePassword />
            </TabPanel>
            <TabPanel px="33px" mt="20px">
              <NotificationSettings />
            </TabPanel>
            <TabPanel px="33px" mt="20px">
              <Text className="fs--28 fw--600" mb="48px">
                <Text className="fs--28 fw--600">
                  Frequently asked questions
                </Text>
                <Text
                  className="fs--14 fw--400"
                  borderBottom="1px solid gray"
                  py="13px"
                >
                  Everything you need to know about the product and billing.
                </Text>
              </Text>

              <FrequentlyAskQuestions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>
    </>
  )
}

function MyProfile({
  setEditProfile,
  isEdit,
}: {
  isEdit: boolean
  setEditProfile: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [userImage, setUserImage] = useState<File | undefined>(undefined)
  const { mutate, isLoading, isSuccess } = useEditProfile()
  const {
    mutate: uploadPic,
    isLoading: picLoading,
    isSuccess: picSuccess,
    data: picData,
  } = useUploadFile()

  const userId = useGetUserId()
  const { data: profileData } = useGetProfile(userId)
  const { register, handleSubmit, getValues, setValue, watch, reset } =
    useForm<EditProfileType>({
      shouldUseNativeValidation: true,
      defaultValues: {
        removePic: false,
      },
    })

  const removePic = watch('removePic')
  const onSubmit = (data: EditProfileType) => {
    if (userImage) {
      uploadPic({ folderName: 'user', field: 'profile', file: userImage })
      setUserImage(undefined)
    } else mutate({ ...profileData, ...data })
  }

  useEffect(() => {
    if (picSuccess && picData?.url) {
      const data = getValues()
      onSubmit({ ...data, profilePicURL: picData?.url })
    }
  }, [picSuccess])

  useEffect(() => {
    if (isSuccess) {
      reset()
      setEditProfile(false)
    }
  }, [isSuccess])

  const handleCancel = () => {
    reset()
    setEditProfile(false)
    setUserImage(undefined)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex alignItems="center" mt="40px">
        <Text flex={0.5} className="fs--16 fw--500">
          Profile Photo
        </Text>
        <Flex flex={0.5} gap="15px" align="center">
          <Avatar
            size="xl"
            src={
              userImage
                ? URL.createObjectURL(userImage)
                : removePic
                ? ''
                : profileData?.profilePicURL
            }
          />
          {isEdit && (
            <>
              <Button
                variant="link"
                color="text"
                fontSize="14px"
                onClick={() => {
                  setValue('profilePicURL', '')
                  setValue('removePic', true)
                }}
              >
                Remove
              </Button>
              <Button
                variant="link"
                fontSize="14px"
                as={FormLabel}
                cursor="pointer"
                htmlFor="inputTag"
                m="0"
              >
                Upload New
              </Button>
              <VisuallyHiddenInput
                id="inputTag"
                type="file"
                onChange={e => setUserImage(e?.target?.files?.[0])}
              />
            </>
          )}
        </Flex>
      </Flex>
      <Flex alignItems="center" my="20px">
        <Text flex={0.5} className="fs--16 fw--500">
          Full Name
        </Text>
        {isEdit ? (
          <InputGroup flex={0.35}>
            <Input
              className="fs--16 fw--500"
              color="muted"
              focusBorderColor="primary.500"
              defaultValue={profileData?.fullName}
              {...register('fullName', {
                required: 'Please enter your full name.',
              })}
            />
          </InputGroup>
        ) : (
          <Text flex={0.5} className="fs--16 fw--500" color="muted">
            {profileData?.fullName}
          </Text>
        )}
      </Flex>
      <Flex alignItems="center" mb="20px">
        <Text flex={0.5} className="fs--16 fw--500">
          Username
        </Text>
        {isEdit ? (
          <InputGroup flex={0.35}>
            <Input
              className="fs--16 fw--500"
              color="muted"
              focusBorderColor="primary.500"
              defaultValue={profileData?.username}
              {...register('username', {
                required: 'Please enter your username name.',
              })}
            />
          </InputGroup>
        ) : (
          <Text flex={0.5} className="fs--16 fw--500" color="muted">
            @{profileData?.username}
          </Text>
        )}
      </Flex>
      <Flex alignItems="center" mb="20px">
        <Text flex={0.5} className="fs--16 fw--500">
          Date of Birth
        </Text>
        {isEdit ? (
          <InputGroup flex={0.35}>
            <Input
              type="date"
              focusBorderColor="primary.500"
              defaultValue={new Date(profileData?.DOB || '').toLocaleDateString(
                'en-CA',
              )}
              {...register('DOB', {
                required: 'Please enter your username name.',
              })}
            />
          </InputGroup>
        ) : (
          <Text flex={0.5} className="fs--16 fw--500" color="muted">
            {`${new Date(profileData?.DOB || '').toLocaleDateString('en-CA')}`}
          </Text>
        )}
      </Flex>
      <Flex alignItems="center" mb="20px">
        <Text flex={0.5} className="fs--16 fw--500">
          Mobile Number
        </Text>
        {isEdit ? (
          <InputGroup flex={0.35}>
            <Input
              type="tel"
              className="fs--16 fw--500"
              color="muted"
              focusBorderColor="primary.500"
              defaultValue={profileData?.phoneNo}
              {...register('phoneNo', {
                required: 'Please enter your phone no. name.',
              })}
            />
          </InputGroup>
        ) : (
          <Text flex={0.5} className="fs--16 fw--500" color="muted">
            {profileData?.phoneNo}
          </Text>
        )}
      </Flex>

      <Flex alignItems="center" mb="20px">
        <Text flex={0.5} className="fs--16 fw--500">
          Email
        </Text>
        <Text flex={0.5} className="fs--16 fw--500" color="muted">
          {profileData?.email}
        </Text>
      </Flex>
      <Flex alignItems="center" mb="40px">
        <Text flex={0.5} className="fs--16 fw--500">
          Gender
        </Text>
        {isEdit ? (
          <Select
            flex={0.35}
            defaultValue={profileData?.gender}
            focusBorderColor="primary.500"
            {...register('gender', {
              required: 'Please select gender.',
            })}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </Select>
        ) : (
          <Text flex={0.5} className="fs--16 fw--500" color="muted">
            {profileData?.gender}
          </Text>
        )}
      </Flex>
      {isEdit && (
        <Box textAlign="end">
          <Button
            variant="outline"
            className="fs--16 fw--500"
            mr="15px"
            isDisabled={isLoading || picLoading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading || picLoading}
            className="fs--16 fw--500"
            type="submit"
            // onClick={() => setEditProfile(false)}
          >
            Save Profile
          </Button>
        </Box>
      )}
    </form>
  )
}

function ChangePassword() {
  const [show, setShow] = useState<boolean>(false)
  const handleClick = () => setShow(!show)
  const { mutate: updatePassword, isLoading, isSuccess } = useUpdatePassword()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<UpdatePasswordForm>()

  const password = watch('password')

  const onSubmit = (data: UpdatePasswordForm) => updatePassword(data?.password)

  useEffect(() => {
    if (isSuccess) reset()
  }, [isSuccess])

  return (
    <Box maxW="600px" as="form" onSubmit={handleSubmit(onSubmit)}>
      <Flex alignItems="center" my="40px">
        <Text flex={0.3} className="fs--16 fw--500">
          New Password
        </Text>
        <FormControl isInvalid={Boolean(errors?.password)} flex={0.7}>
          <InputGroup size="md">
            <InputLeftElement pointerEvents="none">
              <FiLock color="gray" />
            </InputLeftElement>
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              placeholder="Enter password"
              {...register('password', { required: 'New password required.' })}
            />
            <InputRightElement width="4.5rem">
              <Button
                variant="ghost"
                h="1.75rem"
                size="sm"
                color="gray"
                onClick={handleClick}
              >
                {show ? <AiFillEyeInvisible /> : <AiFillEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {errors?.password && (
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          )}
        </FormControl>
      </Flex>
      <Flex alignItems="center" my="40px">
        <Text flex={0.3} className="fs--16 fw--500">
          Confirm Password
        </Text>
        <FormControl isInvalid={Boolean(errors?.confirmPassword)} flex={0.7}>
          <InputGroup size="md">
            <InputLeftElement pointerEvents="none">
              <FiLock color="gray" />
            </InputLeftElement>
            <Input
              pr="4.5rem"
              type={show ? 'text' : 'password'}
              placeholder="Confirm Password"
              {...register('confirmPassword', {
                required: 'Confirm password required.',
                validate: {
                  isMatch: value =>
                    value === password || "Password did'nt match",
                },
              })}
            />
            <InputRightElement width="4.5rem">
              <Button
                variant="ghost"
                h="1.75rem"
                size="sm"
                color="gray"
                onClick={handleClick}
              >
                {show ? <AiFillEyeInvisible /> : <AiFillEye />}
              </Button>
            </InputRightElement>
          </InputGroup>
          {errors?.confirmPassword && (
            <FormErrorMessage>
              {errors?.confirmPassword?.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </Flex>
      <ButtonGroup w="100%" justifyContent="flex-end">
        <Button
          onClick={() => reset()}
          isDisabled={isLoading}
          variant="outline"
        >
          Cancel
        </Button>
        <Button type="submit" isLoading={isLoading}>
          Update Password
        </Button>
      </ButtonGroup>
    </Box>
  )
}
function NotificationSettings() {
  const { data: notificationStatus } = useGetNotificationStatus()
  const { mutate: statusType } = useUpdataNotificationStatus()
  const [allChecked, setAllChecked] = useState<boolean>()
  const checkedAll = () => {
    if (allChecked) {
      statusType({
        eventNotification: false,
        groupNotification: false,
        clubNotification: false,
      })
      setAllChecked(!allChecked)
    } else {
      statusType({
        eventNotification: true,
        groupNotification: true,
        clubNotification: true,
      })
      setAllChecked(!allChecked)
    }
  }
  const bellState = (type: string) => {
    if (type === 'post') {
      statusType({ clubNotification: !notificationStatus?.clubNotification })
    }
    if (type === 'group') {
      statusType({ groupNotification: !notificationStatus?.groupNotification })
    }
    if (type === 'event') {
      statusType({ eventNotification: !notificationStatus?.eventNotification })
    }
  }
  useEffect(() => {
    if (
      notificationStatus?.clubNotification &&
      notificationStatus?.eventNotification &&
      notificationStatus?.groupNotification
    ) {
      setAllChecked(true)
    } else setAllChecked(false)
  }, [notificationStatus])
  return (
    <>
      <Flex justify="space-between" align="center">
        <Text className="fs--28 fw--600">Notifications settings</Text>
        <Switch
          size="md"
          onChange={() => checkedAll()}
          isChecked={allChecked}
        />
      </Flex>

      <Text className="fs--14 fw--400" borderBottom="1px solid gray" py="13px">
        Select the kind of notifications you want to receive about your
        activities
      </Text>
      <Flex py="44px" borderBottom="1px solid gray">
        <Box flex={0.5} pr="40px">
          <Text className="fs--16 fw--500" color="text">
            Post Notifications
          </Text>
          <Text className="fs--14 fw--400" color="muted">
            Get Post notifications in-app to find out what’s going on when
            you’re online
          </Text>
        </Box>
        <Box flex={0.5} textAlign="end">
          <Switch
            size="md"
            isChecked={notificationStatus?.clubNotification}
            onChange={() => bellState('post')}
          />
        </Box>
      </Flex>
      <Flex py="44px" borderBottom="1px solid gray">
        <Box flex={0.5} pr="40px">
          <Text className="fs--16 fw--500" color="text">
            Group Notifications
          </Text>
          <Text className="fs--14 fw--400" color="muted">
            Get group notifications in-app to find out what’s going on when
            you’re online
          </Text>
        </Box>
        <Box flex={0.5} textAlign="end">
          <Switch
            size="md"
            isChecked={notificationStatus?.groupNotification}
            onChange={() => bellState('group')}
          />
        </Box>
      </Flex>
      <Flex py="44px">
        <Box flex={0.5} pr="40px">
          <Text className="fs--16 fw--500" color="text">
            Events Notifications
          </Text>
          <Text className="fs--14 fw--400" color="muted">
            Get event notifications in-app to find out what’s going on when
            you’re online
          </Text>
        </Box>
        <Box flex={0.5} textAlign="end">
          <Switch
            size="md"
            isChecked={notificationStatus?.eventNotification}
            onChange={() => bellState('event')}
          />
        </Box>
      </Flex>
    </>
  )
}
function FrequentlyAskQuestions() {
  return (
    <>
      <Accordion allowToggle>
        {frequentlyAskQuestion.map((item, index) => (
          <AccordionItem key={index}>
            {({ isExpanded }) => (
              <>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {item?.header}
                  </Box>
                  {isExpanded ? (
                    <HiOutlineMinusCircle color="#7C167D" fontSize="20px" />
                  ) : (
                    <HiOutlinePlusCircle color="#7C167D" fontSize="20px" />
                  )}
                </AccordionButton>
                <AccordionPanel pb={4}>{item?.description}</AccordionPanel>
              </>
            )}
          </AccordionItem>
        ))}
      </Accordion>
      <Container
        maxW="832px"
        textAlign="center"
        bg="blackAlpha.100"
        rounded="16px"
        mt="40px"
        p="32px 111px"
      >
        <Box>
          <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
          <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
          <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
        </Box>

        <Text className="fs--20 fw--500" mt="2rem">
          Still have questions?
        </Text>
        <Text className="fs--18 fw--400" color="muted">
          Can’t find the answer you’re looking for? Please email us your problem
          at <b>support@extsy.com</b>
        </Text>
        <Button className="fs--16 fw--500" mt="2rem">
          Get in touch
        </Button>
      </Container>
    </>
  )
}
