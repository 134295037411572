import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react'
import IconButton from 'components/iconButton'
import React, { useState } from 'react'

import { BiPoll } from 'react-icons/bi'
import { TbPhoto, TbVideo } from 'react-icons/tb'
import { useGetCacheData, useGetUserId } from 'utils/hooks'
import { UserProfile } from 'utils/types/user.type'
import MediaContent from './mediaContent'
import PollContent from './pollContent'

export default function UploadContentCard() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const profile = useGetCacheData<UserProfile>([
    'getUserProfile',
    useGetUserId(),
  ])
  const [type, setType] = useState<string>('media')
  return (
    <Card mb="25px" bg="white" overflow="hidden">
      <Flex pt="21px" px="28px" onClick={() => onOpen()}>
        <Avatar
          size="sm"
          name={profile?.fullName}
          src={profile?.profilePicURL}
        />
        <Textarea
          placeholder="Whats happening?"
          size="sm"
          variant="ghost"
          resize="none"
          rows={4}
        />
      </Flex>
      <Flex bg="gray.400" justify="space-evenly" py="5px" align="center">
        <IconButton
          label="Photo"
          onClick={() => {
            setType('media')
            onOpen()
          }}
          color="white"
          Icon={<TbPhoto fontSize="30px" />}
        />
        <Divider orientation="vertical" h="30px" />
        <IconButton
          label="Video"
          onClick={() => {
            setType('media')
            onOpen()
          }}
          color="white"
          Icon={<TbVideo fontSize="30px" />}
        />
        <Divider orientation="vertical" h="30px" />
        <IconButton
          label="Polls"
          onClick={() => {
            setType('polls')
            onOpen()
          }}
          color="white"
          Icon={<BiPoll fontSize="30px" />}
        />
      </Flex>
      <CreatePostModal isOpen={isOpen} onClose={onClose} type={type} />
    </Card>
  )
}

interface Props {
  isOpen: boolean
  onClose: () => void
  type: string
}
function CreatePostModal({ isOpen, onClose, type }: Props) {
  const {
    isOpen: isOpenDisc,
    onOpen: onOpenDisc,
    onClose: onCloseDisc,
  } = useDisclosure()
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={() => {
        onOpenDisc()
      }}
    >
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader className="fs--18 fw--600">
          {type == 'polls' ? 'Create a Poll' : 'Create Post'}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody p="0">
          {type === 'polls' ? (
            <PollContent onOpenDisc={onOpenDisc} />
          ) : (
            <MediaContent />
          )}
          <DiscardChangesModal
            isOpen={isOpenDisc}
            onClose={onCloseDisc}
            parentModalClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface IDiscard {
  isOpen: boolean
  onClose: () => void
  parentModalClose: () => void
}
function DiscardChangesModal({ isOpen, onClose, parentModalClose }: IDiscard) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Discard Changes</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          <Text textAlign="center">
            You have unsaved changes. Changes you made will not be saved.
          </Text>
        </ModalBody>

        <ModalFooter mx="auto">
          <Button
            className="fs--16 fw--500"
            variant="outline"
            mr={3}
            onClick={onClose}
          >
            Go back
          </Button>
          <Button
            className="fs--16 fw--500"
            onClick={() => {
              parentModalClose()
              onClose()
            }}
          >
            Discard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
