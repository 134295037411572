import React from 'react'
import {
  Avatar,
  AvatarBadge,
  Box,
  Container,
  Divider,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
interface Props {
  name: string
  message: string
  time: string
  image: string
  badgeImage: string
}
export default function DisplayNotification({
  name,
  message,
  time,
  image,
  badgeImage,
}: Props) {
  return (
    <Container rounded="10px" bg="primary.50" p="15px" mb="15px">
      <Flex gap="12px">
        <Avatar src={image} size="md">
          <AvatarBadge
            as={'image'}
            w="24px"
            h="24px"
            bg="white"
            borderColor="#BF63C5"
            border="1px"
            top="5"
          >
            <Image src={badgeImage} />
          </AvatarBadge>
        </Avatar>
        <Box>
          <Text className="fs--14 fw--600">{name}</Text>
          <Text className="fs--13 fw--400">{message}</Text>
          <Text className="fs--10 fw--400" my="5px">
            {time}
          </Text>
          <Divider />
        </Box>
      </Flex>
    </Container>
  )
}
