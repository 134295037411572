import React, { useState, useMemo, useEffect } from 'react' // {useEffect} // ,
import {
  Text,
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  FormControl,
  Input,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  AvatarGroup,
  Divider,
  Container,
  ModalHeader,
  Image,
} from '@chakra-ui/react'
import EventModalLogo from 'assets/images/CreateEventDefaultBg.png'
import { BsCameraFill } from 'react-icons/bs'
import FileUploader from 'components/fileUploadCard'
import { useForm } from 'react-hook-form'
import { CreateEvent, Event } from 'utils/types/events.type'
import { useCreateEvent, useUpdateEvent } from 'utils/apis/events.api'
import { useFilterUsersByName, useGetUserId } from 'utils/hooks'
import { useGetAllUsers, useUploadFile } from 'utils/apis/user.api'
import { checkUserAdded } from 'utils/helper'

type props = {
  open: () => void
  close: () => void
  openCheck: boolean
  isSpecific?: boolean
  event?: Event
}
export default function CreateEditEvent({ close, openCheck, event }: props) {
  const [searchText, setSearchText] = useState('')
  const [image, setImage] = useState<File>()

  const { handleSubmit, register, setValue, watch, reset, getValues } =
    useForm<CreateEvent>()
  const {
    mutate: uploadFile,
    data: imageString,
    isSuccess: imageUploaded,
    isLoading: imageUploading,
  } = useUploadFile()
  const { mutate: createEvent, isLoading, isSuccess } = useCreateEvent()
  const {
    mutate: updateGroup,
    isLoading: isUpdating,
    isSuccess: isUpdated,
  } = useUpdateEvent()
  const { data: allUsers } = useGetAllUsers(0, 10)
  const coHosts = watch('coHosts')
  const captureImageURL = watch('captureImageURL')

  const filteredUsers = useMemo(
    () => useFilterUsersByName(allUsers || [], searchText),
    [allUsers, searchText],
  )

  const handleCoHosts = (id: string) => {
    if (coHosts && checkUserAdded(coHosts, id)) {
      setValue(
        'coHosts',
        coHosts?.filter(item => item !== id),
      )
    } else {
      if (coHosts?.length) setValue('coHosts', [...coHosts, id])
      else setValue('coHosts', [id])
    }
  }

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = event => {
    if (event?.target?.files) {
      setImage(event?.target?.files[0])
      setValue('captureImageURL', URL.createObjectURL(event?.target?.files[0]))
    }
  }

  const onSubmit = (data: CreateEvent) => {
    console.log('date 2', data?.startDate)
    if (image) {
      uploadFile({ folderName: 'event', field: 'banner', file: image })
    } else {
      handleCreateEvent(data)
    }
  }

  const handleCreateEvent = (data?: CreateEvent) => {
    const userId = useGetUserId()
    const eventPayload = getValues()
    const payload = data
      ? {
          ...data,
          createdByUserID: userId,
          startDate: new Date(data?.startDate).getTime(),
          endDate: new Date(data?.startDate).getTime(),
        }
      : {
          ...eventPayload,
          startDate: new Date(eventPayload?.startDate).getTime(),
          endDate: new Date(eventPayload?.startDate).getTime(),
          ...(imageString?.url && { captureImageURL: imageString?.url }),
          createdByUserID: userId,
        }

    if (event) {
      updateGroup({ ...event, ...payload })
    } else {
      createEvent(payload)
    }
  }

  useEffect(() => {
    if (isSuccess || isUpdated) close()
  }, [isSuccess, isUpdated])

  useEffect(() => {
    if (imageUploaded && !(isSuccess || isUpdated)) handleCreateEvent()
  }, [imageUploaded])

  useEffect(() => {
    if (!openCheck) reset()
  }, [openCheck])

  return (
    <Modal size="xl" isOpen={openCheck} onClose={close}>
      <ModalOverlay />
      <ModalContent rounded="18px" overflow="hidden">
        <ModalHeader p="0" position="relative">
          <Image
            src={captureImageURL || event?.captureImageURL || EventModalLogo}
            w="100%"
            maxH="200px"
            minH="200px"
            objectFit="cover"
          />
          <Box
            position="absolute"
            bottom="10px"
            left="12px"
            bg="blackAlpha.600"
            rounded="8px"
          >
            <FileUploader
              handleFile={handleFile}
              icon={<BsCameraFill />}
              text="Add Cover Image"
            />
          </Box>
        </ModalHeader>
        <ModalBody rounded="18px" p="0px">
          <form
            style={{ padding: '1.5rem 3rem' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormControl>
              <Text as="p" fontWeight="500" fontSize="16px" mb="15px">
                {event ? 'Edit Event' : 'Create Event'}
              </Text>
              <Input
                mb="12px"
                placeholder="Event name"
                {...register('eventName', {
                  required: 'Event name is required',
                })}
                defaultValue={event?.eventName || ''}
              />
              <Textarea
                placeholder="Description goes here..."
                {...register('detail', {
                  required: 'Description is required',
                })}
                mb="12px"
                defaultValue={event?.detail || ''}
              />
              <Input
                type="date"
                mb="12px"
                placeholder="Start Date"
                {...register('startDate', {
                  required: 'Date is required',
                })}
                defaultValue={
                  new Date(event?.startDate || '').toLocaleDateString(
                    'fr-CA',
                  ) || ''
                }
              />
              <Flex gap="15px">
                <Input
                  placeholder="Start Time"
                  type="time"
                  mb="15px"
                  {...register('startTime', {
                    required: 'Start time is required',
                  })}
                  defaultValue={event?.startTime || ''}
                />
                <Input
                  type="time"
                  mb="15px"
                  placeholder="End Time"
                  {...register('endTime', {
                    required: 'End time is required',
                  })}
                  defaultValue={event?.endTime || ''}
                />
              </Flex>
              <Input mb="15px" placeholder="Meet Link" {...register('link')} />
              <Input
                mb="15px"
                placeholder="Location"
                {...register('location')}
                defaultValue={event?.location || ''}
              />
              <Accordion defaultIndex={[-1]} allowMultiple>
                <AccordionItem border="none">
                  <AccordionButton px={0} _hover={{}} _focus={{}} _active={{}}>
                    <Box px="0px" as="span" flex="1" textAlign="left">
                      <Text as="p" mb="11px">
                        Host
                      </Text>
                      <AvatarGroup size="sm" max={3}>
                        {allUsers?.map(user => {
                          if (
                            coHosts?.indexOf(user?.id) !== undefined &&
                            coHosts?.indexOf(user?.id) >= 0
                          ) {
                            return (
                              <Avatar
                                key={user?.id}
                                name={user?.fullName}
                                src={user?.profilePicURL}
                              />
                            )
                          } else null
                        })}
                      </AvatarGroup>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel px={0} pb={4}>
                    <Input
                      variant="unstyled"
                      placeholder="Search user..."
                      mb="10px"
                      value={searchText}
                      onChange={e =>
                        setSearchText(e?.target?.value?.toLocaleLowerCase())
                      }
                    />
                    <Divider />
                    <Container h="270px" overflowY="scroll" overflowX="hidden">
                      {filteredUsers?.map(({ fullName, profilePicURL, id }) => (
                        <Flex
                          gap="10px"
                          sx={{ '&:last-child': { border: 'none' } }}
                          borderBottom=" 1px solid rgba(102, 102, 102, 0.1)"
                          key={id}
                          alignItems="center"
                          py="10px"
                        >
                          <Avatar
                            size="sm"
                            name={fullName}
                            src={profilePicURL}
                          />
                          <Text flex="1">{fullName}</Text>
                          <Button
                            variant="outline"
                            size="xs"
                            onClick={() => handleCoHosts(id)}
                          >
                            {coHosts && checkUserAdded(coHosts, id)
                              ? 'Remove'
                              : 'Add'}
                          </Button>
                        </Flex>
                      ))}
                    </Container>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </FormControl>
            <Button
              mx="auto"
              mt="15px"
              type="submit"
              display="flex"
              isLoading={isLoading || isUpdating || imageUploading}
            >
              {event ? 'Update Event' : 'Create Event'}
            </Button>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
