import React from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Flex,
  Text,
  useDisclosure,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
} from '@chakra-ui/react'
import { Group } from 'utils/types/groups.type'
import Layout from 'layout'
import CreateGroup from './createGroupModal'
import { Link, useNavigate } from 'react-router-dom'
import {
  useCancelJoinGroup,
  useGetUserGroups,
  useJoinGroup,
} from 'utils/apis/groups.api'
import { useGetPopularGroups } from '../../utils/apis/groups.api'
import nogroup from 'assets/icons/group/groupcover.svg'
import { useGetUserId } from 'utils/hooks'

export default function Groups() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { data: myGroups } = useGetUserGroups(0, 20)
  const { data: popularGroups } = useGetPopularGroups(0, 10)
  return (
    <Layout handleActions={() => console.log('e')}>
      <>
        <InputGroup mb="25px" bg="white">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.300" />
          </InputLeftElement>

          <Input type="tel" placeholder="Explore Groups" />
        </InputGroup>
        <Box boxShadow="base" bg="white" rounded="10px" p="25px">
          <Tabs isLazy>
            <TabList border="none">
              <Tab className="fs--16 ">Popular Groups</Tab>
              <Tab className="fs--16 ">My Groups</Tab>
              <Button
                onClick={onOpen}
                variant="outline"
                ml="auto"
                className="fs--16 fw--500"
                size="sm"
              >
                Create
              </Button>
            </TabList>
            <TabPanels>
              {/* popular groups */}
              <TabPanel p="1rem 0 1rem 1rem">
                {popularGroups?.map(group => (
                  <GroupCard key={group?.groupName} group={group} isPopular />
                ))}
              </TabPanel>
              {/* my groups*/}
              <TabPanel p="1rem 0 1rem 1rem">
                {myGroups?.groups?.length ? (
                  myGroups?.groups?.map(group => (
                    <GroupCard key={group?.groupName} group={group} />
                  ))
                ) : (
                  <Box
                    p="100px 141px"
                    rounded="10px"
                    bg="white"
                    boxShadow="base"
                    textAlign="center"
                  >
                    <Image src={nogroup} alt="img" mx="auto" />
                    <Text className="fs--24 fw--600" mb="15px" mt="35px">
                      No groups joined
                    </Text>
                    <Text color="muted" className="fs--18" mb="35px">
                      Create your new post or follow someone to see their
                      updates
                    </Text>
                    <Button onClick={onOpen} variant="outline" mr="0.5rem">
                      Create Group
                    </Button>
                    <Link to="explore-more">Explore</Link>
                  </Box>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>

        <CreateGroup isOpen={isOpen} onClose={onClose} />
      </>
    </Layout>
  )
}

function GroupCard({
  group,
  isPopular,
}: {
  group: Group
  isPopular?: boolean
}) {
  const navigate = useNavigate()
  const { mutate: joinGroup, isLoading: joinLoading } = useJoinGroup()
  const { mutate: cancelGroup, isLoading: cancelLoading } = useCancelJoinGroup()
  const userId = useGetUserId()
  const state = {
    groupId: group?.id,
    status: userId === group?.user?.id ? 'admin' : 'user',
  }
  return (
    <>
      <Flex mt="15px">
        <Avatar size="md" name="Ryan Florence" src={group?.captureImageURL} />
        <Flex
          ml="15px"
          pb="15px"
          borderBottom="1px solid rgba(180, 171, 171, 0.66)"
          flex="1"
        >
          <Box flex="1">
            <Flex>
              <Text className="fs--16 fw--600">{group?.groupName}</Text>
              {!isPopular && (
                <Badge
                  className="fs--11 fw--600"
                  color="text"
                  bg="gray.200"
                  ml="10px"
                  p="3px 10px"
                >
                  {/* {group?.user?.username} */}
                  not define
                </Badge>
              )}
            </Flex>

            <Text className="fs--12 fw--400" maxW="450px" color="muted">
              {group?.description}
            </Text>
            <AvatarGroup size="xs" max={4} mt="5px">
              {group?.topMembers?.map(({ id, profilePicURL }) => (
                <Avatar key={id} name={id} src={profilePicURL} />
              ))}
            </AvatarGroup>
          </Box>
          <Box>
            {isPopular ? (
              <Button
                size="sm"
                variant={`${group?.myStatus?.statusType ? 'solid' : 'outline'}`}
                isLoading={joinLoading || cancelLoading}
                onClick={() =>
                  group?.myStatus?.statusType
                    ? cancelGroup(group?.id)
                    : joinGroup(group?.id)
                }
              >
                {group?.myStatus?.statusType ? 'Cancel' : 'Join'}
              </Button>
            ) : (
              <Button
                size="sm"
                variant="outline"
                onClick={() =>
                  navigate(`/group-details/${group?.id}`, {
                    state,
                  })
                }
              >
                View
              </Button>
            )}
            <Text className="fs--14" color="muted" mt="12px">
              {group?.membersCount} Users
            </Text>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}
