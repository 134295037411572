import React from 'react'
import { Navigate } from 'react-router-dom'
import { parseJwt } from 'utils/helper'

export default function PrivateRoute({ children }: { children: JSX.Element }) {
  const { REACT_APP_EXCHANGE_URL } = process.env
  const url = window.location.search

  const urlToken = url.split('access_token=Bearer%20')[1]
  if (urlToken) {
    localStorage.setItem('access_token', urlToken)
    const id = parseJwt(urlToken)?.id
    localStorage.setItem('userId', id)
  }

  const token = localStorage.getItem('access_token')
  if ((token != 'undefined' && token) || urlToken) {
    if (urlToken) return <Navigate to="/" />
    return children
  }
  if (token == 'undefined' || !token) {
    window.location.assign(`${REACT_APP_EXCHANGE_URL}/login`)
  }

  return null
}
