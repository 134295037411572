import React from 'react'
import { Badge, Container, Divider, Flex, Text } from '@chakra-ui/layout'
import logo from 'assets/images/logo.svg'
import {
  Avatar,
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  Image,
  IconButton,
  useColorMode,
  AvatarBadge,
  Switch,
  Link as ExternalLink,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { menu } from 'utils/const'
import { Link, useNavigate } from 'react-router-dom'
// import { NotificationIcon } from 'assets/icons'
import NotificationPopUp from 'components/notificationPopup'
import { FiBell } from 'react-icons/fi'
import { AiFillSetting } from 'react-icons/ai'
import { BsMoonFill } from 'react-icons/bs'
import { MdLogout } from 'react-icons/md'
import { useGetProfile } from 'utils/apis/user.api'
import { UserProfile } from 'utils/types/user.type'

const { REACT_APP_EXCHANGE_URL } = process.env

export default function Header() {
  const { data: profileData } = useGetProfile(
    localStorage.getItem('userId') || '',
  )
  return (
    <Flex
      bg="white"
      as="header"
      p="1.3rem"
      alignItems="center"
      boxShadow=" 0px 0px 15px 0px #0000001A"
      justifyContent="space-between"
    >
      <Link to="/">
        <Image src={logo} />
      </Link>

      <Flex alignItems="center" as="nav" gap="2">
        {menu.map(({ label, subMenu, path }) =>
          subMenu ? (
            <Menu key={label}>
              <MenuButton
                fontSize="14px"
                fontWeight="300"
                variant="ghost"
                color="none"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                {label}
              </MenuButton>
              <MenuList>
                {subMenu?.map(({ label, path }) => (
                  <MenuItem as={Link} to={path} key={label}>
                    {label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <Text
              as={Link}
              to={path}
              key={label}
              fontSize="14px"
              fontWeight="300"
              mr="1rem"
            >
              {label}
            </Text>
          ),
        )}
        <ExternalLink
          href={REACT_APP_EXCHANGE_URL}
          fontSize="14px"
          fontWeight="300"
          mr="1rem"
        >
          Exchange Dashboard
        </ExternalLink>
      </Flex>
      <Box
        display="flex"
        alignItems="center"
        gap="1rem"
        justifyContent="center"
      >
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <IconButton
              _active={{}}
              variant="ghost"
              aria-label="notification"
              icon={<FiBell fontSize="26px" color="#666666" />}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <NotificationPopUp isPopUp={true} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {/* </Box> */}
        <Popover placement="bottom-start">
          <PopoverTrigger>
            <Button
              as={Box}
              bg="transparent"
              _active={{}}
              _hover={{ bg: 'transparent' }}
            >
              <Avatar
                size="md"
                name={profileData?.fullName}
                src={profileData?.profilePicURL}
              />
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody py="10px">
              <ProfileMenu profileData={profileData} />
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
    </Flex>
  )
}
// header menu
function ProfileMenu({ profileData }: { profileData?: UserProfile }) {
  const navigate = useNavigate()
  const { toggleColorMode } = useColorMode()
  const handleLogout = () => {
    localStorage.removeItem('access_token')
    navigate('/login')
  }

  return (
    <Container maxW="308px">
      <Flex
        gap="17px"
        mb="13px"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('/profile')}
      >
        <Avatar
          size="md"
          name={profileData?.fullName}
          src={profileData?.profilePicURL}
        >
          <AvatarBadge boxSize="0.8em" bg="green.400" />
        </Avatar>
        <Flex alignItems="center">
          <Box>
            <Text className="fs--14 fw--500">{profileData?.fullName}</Text>
            <Text className="fs--14 fw--400" color="muted">
              See Your Profile
            </Text>
          </Box>
          <Badge color="text" bg="gray.200" ml="10px" p="3px 10px">
            Owner
          </Badge>
        </Flex>
      </Flex>
      <Divider mb="10px" />
      <Button
        className="fs--16 fw--400"
        bg="transparent"
        color="text"
        px="0"
        _hover={{}}
      >
        <IconButton
          isRound
          aria-label="Settings"
          bg="gray.200"
          mr="20px"
          _hover={{}}
          icon={<AiFillSetting size="26px" color="#6374A0" />}
          onClick={() =>
            navigate('/edit-profile', { state: { path: 'notification' } })
          }
        />
        Settings
      </Button>
      <Flex
        width="100%"
        my="10px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          className="fs--16 fw--400"
          bg="transparent"
          color="text"
          px="0"
          _hover={{}}
        >
          <IconButton
            isRound
            aria-label="moon"
            bg="gray.200"
            mr="20px"
            _hover={{}}
            icon={<BsMoonFill size="26px" color="#6374A0" />}
          />
          Dark Mode
        </Button>

        <Switch colorScheme="primary" onChange={toggleColorMode} />
      </Flex>
      <Divider mb="10px" />
      <Button
        className="fs--16 fw--400"
        bg="transparent"
        color="text"
        px="0"
        _hover={{}}
        onClick={handleLogout}
      >
        <IconButton
          isRound
          aria-label="Log out"
          bg="gray.200"
          mr="20px"
          _hover={{}}
          icon={<MdLogout size="26px" color="#6374A0" />}
        />
        Log out
      </Button>
    </Container>
  )
}
