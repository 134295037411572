import React from 'react'
import {
  VStack,
  Text,
  Image,
  Box,
  Button,
  useDisclosure,
  Heading,
} from '@chakra-ui/react'
import EventCard from 'components/eventsCard'
import Layout from 'layout'
import NoEvent from 'assets/images/noEvent.png'
import CreateEventLogo from 'assets/images/CreateEventLogo.png'
import CreateEditEvent from 'components/eventEditCreateModal'
import { useGetAllEvents } from 'utils/apis/events.api'
export default function EventsTimeLine() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: allEvents } = useGetAllEvents(0, 20)

  return (
    <Layout handleActions={() => console.log('e')}>
      <>
        {/* #844D95 */}
        <Box
          background="linear-gradient(90deg, #F5ECF6 50%, #844D95 50%)"
          rounded="18px"
          mb="30px"
          p="25px"
        >
          <Box background={`url(${CreateEventLogo}) no-repeat right center`}>
            <Text
              mt="14px"
              as="span"
              fontSize={12}
              color="primary.400"
              mb="5px"
            >
              - Thomas Edison
            </Text>
            <Text as="p" fontSize="18px" w="258px" mb="25px">
              Good fortune is what happens when opportunity meets with planning.
              Create Event
            </Text>
            <Button
              onClick={() => onOpen()}
              fontSize="16px"
              fontWeight="400"
              mb="14px"
            >
              Create Event
            </Button>
          </Box>
        </Box>
        {allEvents?.length ? (
          <>
            {allEvents?.map(({ event, myStatus }) => {
              return (
                <EventCard event={event} status={myStatus} key={event?.id} />
              )
            })}
          </>
        ) : (
          <VStack
            borderRadius="10px"
            bg="#ffffff"
            py="100px"
            px="50px"
            width="100%"
            height="598px"
          >
            <Image mb={5} w={370} h={284} src={NoEvent} />
            <Heading as="h2" fontSize="24px">
              NoEvent
            </Heading>
            <Text color="muted" as="p" fontSize={18}>
              Lorem ipsum dolor sit amet consectetur. Eget nisi ullamcorper
              ornare commodo aenean. Sit gravida ultrices elementum varius.
            </Text>
          </VStack>
        )}
        <CreateEditEvent openCheck={isOpen} open={onOpen} close={onClose} />
      </>
    </Layout>
  )
}
