import React from 'react'
import Layout from 'layout'
import EventCard from 'components/eventsCard'
import { useParams } from 'react-router-dom'
import { useDeleteEvent, useGetEvent } from 'utils/apis/events.api'
import ConfirmModal from 'components/confirmModal'
import { useDisclosure } from '@chakra-ui/react'
import CreateEditEvent from 'components/eventEditCreateModal'

export default function EventDetail() {
  const { id } = useParams()
  const { data: eventDetails } = useGetEvent(id)
  const { mutate: deleteEvent, isLoading } = useDeleteEvent()
  const {
    isOpen: isDelete,
    onOpen: openDelete,
    onClose: closeDelete,
  } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleEventActions = (e: string) => {
    if (e === 'delete') openDelete()
    if (e === 'edit') onOpen()
  }

  return (
    <Layout handleActions={e => handleEventActions(e)}>
      <>
        {eventDetails && (
          <>
            <EventCard
              event={eventDetails?.event}
              status={eventDetails?.myStatus}
              specific
            />
            {eventDetails?.event && (
              <CreateEditEvent
                event={eventDetails?.event}
                openCheck={isOpen}
                open={onOpen}
                close={onClose}
              />
            )}
          </>
        )}

        <ConfirmModal
          title="Delete Event !"
          message="Are you sure you want to delete this event? This action cannot be undone."
          type="delete"
          isLoading={isLoading}
          isOpen={isDelete}
          onClose={closeDelete}
          onOk={() => id && deleteEvent(id)}
        />
      </>
    </Layout>
  )
}
