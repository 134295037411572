import { Menu, Notification } from './types/global.type'
import vector from 'assets/icons/badge.svg'

export const BASE_URL = ''

export const menu: Menu[] = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'P2P',
    path: '/market',
  },
]

export const notification: Notification[] = [
  {
    image: 'https://bit.ly/sage-adebayo',
    badgeImg: vector,
    name: 'zubair',
    message: 'dafsdfad fdsfds fdfdfdfd ',
    date: 'Today, 3.52pm',
  },
  {
    image: 'https://bit.ly/sage-adebayo',
    badgeImg: vector,
    name: 'Bachnum',
    message:
      ' Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit..',
    date: 'Today, 9.52pm',
  },
  {
    image: 'https://bit.ly/sage-adebayo',
    badgeImg: vector,
    name: 'Jason Roy',
    message: 'dafsdfad fdsfds fdfdfdfd ',
    date: 'Today, 12.52pm',
  },
]
